<template>
  <div class="wrapper">
    <div class="page-header page-header-blank">
      <div class="container" style="padding-top: 87px; padding-bottom: 0px;">
        <div class="pdf">
          <pdf v-for="i in numPages" :key="i" :src="src" :page="i"></pdf>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf
  },
  data() {
    return {
      src: undefined,
      numPages: undefined
    };
  },
  mounted() {
    let loadingTask = pdf.createLoadingTask("img/hedron_contract_guide.pdf");

    loadingTask.promise.then(pdf => {
      this.src = loadingTask;
      this.numPages = pdf.numPages;
    });
  }
};
</script>
<style>
.pdf {
  display: block;
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
}
</style>
