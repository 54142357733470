<template>
  <div class="wrapper">
    <header class="header-4 skew-separator">
      <div class="header-wrapper">
        <div class="page-header header-img">
          <div class="overlay">
            <video
              playsinline="playsinline"
              autoplay="autoplay"
              muted="muted"
              loop="loop"
            >
              <source src="media/overlay.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="container text-center">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <img src="img/brand/white_center.png" alt="logo" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <h1
                  class="display-3 text-white"
                  style="margin-top: 20px; margin-bottom: 20px;"
                >
                  Take advantage of your HEX stake's maturity. Trade it, borrow
                  against it, get free liquidity.
                </h1>
                <base-button
                  icon="fa fa-bank"
                  type="primary"
                  class="mt-3 mb-sm-0"
                  v-on:click="$router.push('dapp')"
                  >Mint Your Hedron</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <Intro></Intro>
    <FlowChart></FlowChart>
    <Factors></Factors>
    <Calculator></Calculator>
    <FAQ></FAQ>
  </div>
</template>
<script>
import FlowChart from "./sections/FlowChart.vue";
import Intro from "./sections/Intro.vue";
import Factors from "./sections/Factors.vue";
import Calculator from "./sections/Calculator.vue";
import FAQ from "./sections/FAQ.vue";
import { hdrnLaunch } from "../constants";

export default {
  components: {
    FlowChart,
    Intro,
    Factors,
    Calculator,
    FAQ
  },
  data: function() {
    return {
      now: new Date(),
      hdrnLaunch: hdrnLaunch,
      bonus: null,
      until: null
    };
  },    
};
</script>
<style>
.overlay {
  opacity: 0.5 !important;
}
video {
  width: 100% !important;
  min-width: 1280px !important;
}
</style>
