<template>
  <div class="cd-sections" id="tables">
    <div class="tables-2 mb-5">
      <div class="container-fluid">
        <div v-if="stakes.length >= 1" class="row">
          <div class="col-md-10 mx-auto" style="overflow-x: auto;">
            <div>
              <div class="table-responsive mb-0" style="min-height: 400px">
                <table class="table">
                  <tbody>
                    <tr class="bg-primary">
                      <td class="text-white text-center">
                        <b>Stake Type</b>
                      </td>
                      <td class="text-white text-center">
                        <b>Stake Status</b>
                      </td>
                      <td class="text-white text-center">
                        <b>Stake B-Shares</b>
                      </td>
                      <td class="text-white text-center">
                        <b>Served Days</b>
                      </td>
                      <td class="text-white text-center">
                        <b>Bonus Multiplier</b>
                      </td>
                      <td class="text-white text-center">
                        <b>Mintable HDRN (Est.)</b>
                      </td>
                      <td class="text-white text-center">
                        <b>Borrowable HDRN (Est.)</b>
                      </td>
                      <td></td>
                    </tr>
                    <tr v-for="stake in stakes" v-bind:key="stake.stakeId">
                      <td class="text-center">
                        <h6>{{ stake.stakeType }}</h6>
                      </td>
                      <td
                        v-if="stake.stakeType == 'Native'"
                        class="text-center"
                      >
                        <h6>Mint Only</h6>
                      </td>
                      <td
                        v-else-if="
                          stake.stakeType == 'Instanced' &&
                            !stake.isLoaned &&
                            !stake.isTokenized
                        "
                        class="text-center"
                      >
                        <h6>Unlocked</h6>
                      </td>
                      <td
                        v-else-if="
                          stake.stakeType == 'Instanced' &&
                            stake.isLoaned &&
                            !stake.isTokenized
                        "
                        class="text-center"
                      >
                        <h6>Locked</h6>
                      </td>
                      <td
                        v-else-if="
                          stake.stakeType == 'Instanced' && stake.isTokenized
                        "
                        class="text-center"
                      >
                        <h6>Tokenized</h6>
                      </td>
                      <td class="text-center">
                        <h6>
                          {{
                            String(
                              Math.ceil(
                                utils.formatUnits(stake.stakeShares, 7)
                              ) / 100
                            ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h6>
                      </td>
                      <td class="text-center">
                        <h6 v-if="stake.servedDays < 0">Pending</h6>
                        <h6 v-else>
                          {{ stake.servedDays }} / {{ stake.stakedDays }}
                        </h6>
                      </td>
                      <td class="text-center">
                        <h6>{{ stake.bonusMultiplier }}</h6>
                      </td>
                      <td class="text-center">
                        <h6 v-if="stake.servedDays < 0">0</h6>
                        <h6 v-else>
                          {{
                            String(
                              Math.ceil(
                                utils.formatUnits(stake.mintableICS, 7)
                              ) / 100
                            ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h6>
                      </td>
                      <td
                        v-if="stake.stakeType == 'Native'"
                        class="text-center"
                      >
                        <h6>N/A</h6>
                      </td>
                      <td
                        v-else-if="stake.stakeType == 'Instanced'"
                        class="text-center"
                      >
                        <h6>
                          {{
                            String(
                              Math.ceil(
                                utils.formatUnits(stake.loanableHDRN, 7)
                              ) / 100
                            ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h6>
                      </td>
                      <td>
                        <base-dropdown
                          v-if="
                            stake.stakeType == 'Instanced' &&
                              !stake.minting &&
                              !stake.ending &&
                              !stake.detokenizing &&
                              !stake.accounting
                          "
                        >
                          <a
                            role="button"
                            class="btn-sm btn-primary text-white"
                            slot="title"
                            data-toggle="dropdown"
                            data-boundary="viewport"
                          >
                            <span class="nav-link-inner--text">ACTIONS</span>
                          </a>
                          <a
                            v-if="!stake.isLoaned && !stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              hsiIndex == stake.hsiIndex;
                              hsiAddress == stake.hsiAddress;
                              mintInstanced(stake.hsiIndex, stake.hsiAddress);
                            "
                            ><span>Mint</span></a
                          >
                          <a
                            v-if="!stake.isLoaned && !stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              modals.modal0 = true;
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              calcPayment();
                              estLoanAmount = stake.loanableHDRN;
                              estLoanLength =
                                stake.stakedDays - stake.mintedDays;
                            "
                            ><span>Borrow</span></a
                          >
                          <a
                            v-if="!stake.isLoaned && !stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              modals.modal2 = true;
                            "
                            ><span>Tokenize</span></a
                          >
                          <a
                            v-if="!stake.isLoaned && !stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              endStake(stake.hsiIndex, stake.hsiAddress);
                            "
                            ><span>End Stake</span></a
                          >
                          <a
                            v-if="stake.isLoaned && !stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              modals.modal3 = true;
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              paymentsMade = stake.paymentsMade;
                              totalPayments = stake.totalPayments;
                              loanStart = stake.loanStart;
                              calcPayment();
                            "
                            ><span>Payment</span></a
                          >
                          <a
                            v-if="stake.isLoaned && !stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              modals.modal4 = true;
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              calcPayoff();
                            "
                            ><span>Payoff</span></a
                          >
                          <a
                            v-if="!stake.isLoaned && stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              modals.modal5 = true;
                              hsiTokenId = stake.tokenId;
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                            "
                            ><span>Transfer</span></a
                          >
                          <a
                            v-if="!stake.isLoaned && stake.isTokenized"
                            class="dropdown-item"
                            v-on:click="
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              hsiTokenId = stake.tokenId;
                              stakeDetokenize(stake.tokenId);
                            "
                            ><span>Detokenize</span></a
                          >
                          <a
                            class="dropdown-item"
                            v-on:click="
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              modals.modal6 = true;
                            "
                            ><span>Stake Info</span></a
                          >
                          <a
                            class="dropdown-item"
                            v-on:click="
                              hsiIndex = stake.hsiIndex;
                              hsiAddress = stake.hsiAddress;
                              goodAccounting(stake.hsiAddress);
                            "
                            ><span>Good Accounting</span></a
                          >
                        </base-dropdown>
                        <base-button
                          v-else-if="
                            stake.stakeType == 'Instanced' &&
                              (stake.minting ||
                                stake.ending ||
                                stake.accounting ||
                                stake.detokenizing)
                          "
                          :icon="stake.buttonIcon"
                          type="secondary"
                          class="btn-sm"
                          disabled
                          >{{ stake.buttonText }}</base-button
                        >
                        <base-dropdown
                          v-else-if="
                            (stake.servedDays == 0 || stake.mintableICS > 0) &&
                              !stake.minting &&
                              !stake.claimed
                          "
                        >
                          <a
                            role="button"
                            class="btn-sm btn-primary text-white"
                            slot="title"
                            data-toggle="dropdown"
                            data-boundary="viewport"
                          >
                            <span class="nav-link-inner--text">ACTIONS</span>
                          </a>
                          <a
                            v-if="!stake.claimed"
                            class="dropdown-item"
                            v-on:click="
                              claimNative(stake.stakeIndex, stake.stakeId)
                            "
                            ><span>Claim</span></a
                          >
                          <a
                            class="dropdown-item"
                            v-on:click="
                              mintNative(stake.stakeIndex, stake.stakeId)
                            "
                            ><span>Mint</span></a
                          >
                        </base-dropdown>
                        <base-button
                          v-else-if="
                            (stake.servedDays == 0 || stake.mintableICS > 0) &&
                              !stake.minting
                          "
                          :icon="stake.buttonIcon"
                          type="primary"
                          class="btn-sm"
                          v-on:click="
                            mintNative(stake.stakeIndex, stake.stakeId)
                          "
                          >{{ stake.buttonText }}</base-button
                        >
                        <base-button
                          v-else
                          :icon="stake.buttonIcon"
                          type="secondary"
                          class="btn-sm"
                          disabled
                          >{{ stake.buttonText }}</base-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hdrnLaunch.getTime() > now.getTime()" class="row">
          <div class="col-md-6 mx-auto text-center">
            <br />
            <br />
            <span class="fa fa-clock-o fa-5x"></span>
            <h2 class="title mb-5">Looks like you're a bit early!</h2>
            <h5>
              The Hedron contract wont start processing transactions until
              February 26th 2022 (UTC).
            </h5>
            <p>In the mean time, why don't you come join us on telegram?</p>
            <vue-countdown
              :time="hdrnLaunch - now"
              v-slot="{ days, hours, minutes, seconds }"
            >
              Time Remaining：{{ days }} days, {{ hours }} hours,
              {{ minutes }} minutes, {{ seconds }} seconds.
            </vue-countdown>
          </div>
        </div>
        <div v-else-if="!address" class="row">
          <div class="col-md-6 mx-auto text-center">
            <br />
            <br />
            <span class="fa fa-lock fa-5x"></span>
            <h2 class="title mb-5">Looks like MetaMask is locked!</h2>
            <h5>
              To use this app you'll have to unlock MetaMask and connect a
              wallet.
            </h5>
            <p>
              If you still see this message after unlocking MetaMask, press
              "Connect Metamask"
            </p>
          </div>
        </div>
        <div v-else-if="stakes.length < 1 && !loaded" class="row">
          <div class="col-md-6 mx-auto text-center">
            <br />
            <br />
            <span class="fa fa-spinner fa-pulse fa-5x fa-fw"></span>
            <h2 class="title mb-5">Loading your HEX stakes!</h2>
            <h5>This may take a few seconds.</h5>
            <p>You must have at least one active HEX stake to use Hedron.</p>
          </div>
        </div>
        <div v-else-if="stakes.length < 1 && loaded" class="row">
          <div class="col-md-6 mx-auto text-center">
            <br />
            <br />
            <span class="fa fa-ban fa-5x"></span>
            <h2 class="title mb-5">No HEX stakes found!</h2>
            <h5>You must have at least one active HEX stake to use Hedron.</h5>
            <p>
              You can create an instanced HEX stake using the "Stake HEX"
              button.
            </p>
            <p>
              You can create native HEX stake by visiting
              <a href="https://go.hex.com">go.hex.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modals.modal0">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Borrow Hedron (HDRN)
        </h5>
      </template>
      <div>
        <p style="font-size: 14px">
          Advances are executed directly between the Hedron smart contract and
          an end user, no entity directly benefits from these advances other
          than the end user.
        </p>
        <h6>Advance Premiums</h6>
        <p style="font-size: 14px">
          Hedron sets the advance premium rate to one half of the HEX APY on the
          Hedron day of advance initialization. This rate is fixed throughout
          the life of the advance.
        </p>
        <h6>Advance Payments</h6>
        <p style="font-size: 14px">
          Hedron expects payments to be made at least every thirty days starting
          from the Hedron day of advance initialization. Payments are made one
          at a time and can be made in advance. Payments will always charge
          premiums. The payment itself is burnt by the contract.
        </p>
        <h6>Advance Payoffs</h6>
        <p style="font-size: 14px">
          Using the payoff function, an end user can payoff their entire advance
          at any point only paying premiums up to the current Hedron day. The
          payoff itself is burnt by the contract.
        </p>
        <h6>Advance Risks</h6>
        <p style="font-size: 14px">
          There are no late payment penalties, however if a advance goes 90 or
          more days without payment any other user will have the option to pay
          off your advance balanace and liquidate (take ownership) of your HEX
          stake collateral in an auction.
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal0 = false"
          >Close</base-button
        >
        <base-button
          type="primary"
          v-on:click="
            modals.modal0 = false;
            modals.modal1 = true;
          "
          >I Acknowledge</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal1">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Borrow Hedron (HDRN)
        </h5>
      </template>
      <div>
        <h5 class="text-center" style="margin-bottom: 50px;">
          Amount:
          {{
            String(utils.formatUnits(estLoanAmount, 9)).replace(
              /(\d)(?=(\d{3})+\.)/g,
              "$1,"
            )
          }}
          HDRN
        </h5>
        <p class="text-center" style="margin-bottom: 50px;">
          You will be expected to make
          <mark>{{ Math.ceil(estLoanLength / 30) }}</mark> payments with an
          estimated payment amount of
          <mark
            >{{
              String(
                Math.ceil(utils.formatUnits(estPayment.add(estInterest), 7)) /
                  100
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}
            HDRN</mark
          >. Payments are due every 30 Hedron days starting from the day of
          advance creation.
        </p>
        <p class="text-center">
          Each payment will be comprised of the following principal and premium
          amounts. The estimated premium rate is
          <mark v-if="estPayment > 0"
            >{{
              String(
                estInterest
                  .mul(10000)
                  .div(estPayment)
                  .toNumber() / 100
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}%</mark
          >.
        </p>
        <div class="row text-center" style="margin-bottom: 25px;">
          <div class="col-6 mx-auto">
            <p>
              <b>Principal:</b>
              <mark
                >{{
                  String(
                    Math.ceil(utils.formatUnits(estPayment, 7)) / 100
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                HDRN</mark
              >
            </p>
          </div>
          <div class="col-6 mx-auto">
            <p>
              <b>Premium:</b>
              <mark
                >{{
                  String(
                    Math.ceil(utils.formatUnits(estInterest, 7)) / 100
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                HDRN</mark
              >
            </p>
          </div>
        </div>
        <p class="text-center" style="font-size: 14px;">
          *Your final payment may be substantially less than the estimated
          payment amount, payments are calculated per Hedron day and in most
          cases your final payment will be calculated against less than thirty
          days.
        </p>
        <p class="text-center" style="font-size: 14px;">
          **HEX stakes with active advances will no longer be able to be minted,
          borrowed, tokenized, or end staked (including EES) until the advance
          is paid in full. You will still be able to use Good Accouting.
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal1 = false"
          >Close</base-button
        >
        <base-button
          v-if="!loanPending"
          type="primary"
          v-on:click="loanInstanced()"
          >Borrow HDRN</base-button
        >
        <base-button
          v-else-if="loanPending"
          type="secondary"
          icon="fa fa-spinner fa-pulse fa-fw"
          disabled
          v-on:click="loanInstanced()"
          >Please Wait</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal2">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Tokenize HEX Stake
        </h5>
      </template>
      <div class="text-center">
        <p style="font-size: 14px">
          Tokenizing a HEX stake will remove the stake from your control and
          issue you an NFT token which is linked to that stake.
        </p>
        <p style="font-size: 14px">
          This NFT can be sent to other wallets or traded on any compatible NFT
          marketplace. Afterwards, the new owner may choose detokenize the stake
          in order to be granted control of it. You may also detokenize the
          stake yourself if you wish to regain control of it.
        </p>
        <p style="font-size: 14px">
          <b
            >Tokenized HEX stakes cannot mint, borrow, or otherwise interact
            with Hedron and it's features until it has been detokenized! Good
            Accounting however can still be called against it.</b
          >
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal2 = false"
          >Close</base-button
        >
        <base-button
          v-if="!tokenizePending"
          type="primary"
          v-on:click="stakeTokenize()"
          >Tokenize Stake</base-button
        >
        <base-button
          v-else-if="tokenizePending"
          type="secondary"
          icon="fa fa-spinner fa-pulse fa-fw"
          disabled
          v-on:click="loanInstanced()"
          >Please Wait</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal3">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Hedron Advance Payment
        </h5>
      </template>
      <div>
        <h5 class="text-center" style="margin-bottom: 50px;">
          Payment:
          <mark
            >{{
              String(utils.formatUnits(estPayment.add(estInterest), 9)).replace(
                /(\d)(?=(\d{3})+\.)/g,
                "$1,"
              )
            }}
            HDRN</mark
          >
        </h5>
        <p class="text-center">
          This is payment
          <mark>{{ paymentsMade + 1 }} out of {{ totalPayments }}</mark
          >. This payment is due in
          <mark
            >{{
              loanStart +
                paymentsMade * 30 -
                Math.floor(
                  (now.getTime() - this.hdrnLaunch.getTime()) /
                    (1000 * 3600 * 24)
                ) +
                30
            }}
            days</mark
          >.
        </p>
        <p class="text-center">
          If this payment is not made in
          <mark
            >{{
              loanStart +
                paymentsMade * 30 -
                Math.floor(
                  (now.getTime() - this.hdrnLaunch.getTime()) /
                    (1000 * 3600 * 24)
                ) +
                90
            }}
            days</mark
          >, then your stake will become eligible for liquidation by another
          user!
        </p>
        <p class="text-center">
          Your payment is comprised of the following principal and premium
          amounts. Your current premium rate is
          <mark v-if="estPayment > 0"
            >{{
              String(
                estInterest
                  .mul(10000)
                  .div(estPayment)
                  .toNumber() / 100
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}%</mark
          >.
        </p>
        <div class="row text-center" style="margin-bottom: 25px;">
          <div class="col-6 mx-auto">
            <p>
              <b>Principal:</b>
              <mark
                >{{
                  String(
                    Math.ceil(utils.formatUnits(estPayment, 7)) / 100
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                HDRN</mark
              >
            </p>
          </div>
          <div class="col-6 mx-auto">
            <p>
              <b>Premium:</b>
              <mark
                >{{
                  String(
                    Math.ceil(utils.formatUnits(estInterest, 7)) / 100
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                HDRN</mark
              >
            </p>
          </div>
        </div>
        <p class="text-center" style="font-size: 14px;">
          *Your final payment may be substantially less than the standard
          payment amount, payments are calculated per Hedron day and in most
          cases your final payment will be calculated against less than thirty
          days.
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal3 = false"
          >Close</base-button
        >
        <base-button
          v-if="estPayment.add(estInterest).gt(hdrnBalance)"
          type="primary"
          disabled
          >Insufficient Balance</base-button
        >
        <base-button
          v-else-if="!paymentPending"
          type="primary"
          v-on:click="loanPayment()"
          >Make Payment</base-button
        >
        <base-button
          v-else-if="paymentPending"
          type="secondary"
          icon="fa fa-spinner fa-pulse fa-fw"
          disabled
          >Please Wait</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal4">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Hedron Advance Payoff
        </h5>
      </template>
      <div>
        <h5 class="text-center" style="margin-bottom: 50px;">
          Payoff:
          <mark
            >{{
              String(utils.formatUnits(estPayment.add(estInterest), 9)).replace(
                /(\d)(?=(\d{3})+\.)/g,
                "$1,"
              )
            }}
            HDRN</mark
          >
        </h5>
        <p class="text-center">
          This payoff is comprised of the following principal and premium
          amounts. Your payoff premium rate is
          <mark v-if="estPayment > 0"
            >{{
              estInterest
                .mul(10000)
                .div(estPayment)
                .toNumber() / 100
            }}%</mark
          >.
        </p>
        <div class="row text-center" style="margin-bottom: 25px;">
          <div class="col-6 mx-auto">
            <p>
              <b>Principal:</b>
              <mark
                >{{
                  String(
                    Math.ceil(utils.formatUnits(estPayment, 7)) / 100
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                HDRN</mark
              >
            </p>
          </div>
          <div class="col-6 mx-auto">
            <p>
              <b>Premium:</b>
              <mark
                >{{
                  String(
                    Math.ceil(utils.formatUnits(estInterest, 7)) / 100
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                HDRN</mark
              >
            </p>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal4 = false"
          >Close</base-button
        >
        <base-button
          v-if="estPayment.add(estInterest).gt(hdrnBalance)"
          type="primary"
          disabled
          >Insufficient Balance</base-button
        >
        <base-button
          v-else-if="!payoffPending"
          type="primary"
          v-on:click="loanPayoff()"
          >Payoff Loan</base-button
        >
        <base-button
          v-else-if="payoffPending"
          type="secondary"
          icon="fa fa-spinner fa-pulse fa-fw"
          disabled
          >Please Wait</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal5">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Transfer Tokenized Stake
        </h5>
      </template>
      <div>
        <base-input
          v-model="transferAddress"
          placeholder="Address to Transfer NFT To"
          alternative
        ></base-input>
        <p class="text-center">
          Once transfered, you will no longer have access to this stake!
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal5 = false"
          >Close</base-button
        >
        <base-button
          v-if="!transferPending"
          type="primary"
          v-on:click="hsiTransfer()"
          >Transfer NFT</base-button
        >
        <base-button
          v-else-if="transferPending"
          type="secondary"
          icon="fa fa-spinner fa-pulse fa-fw"
          disabled
          >Please Wait</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal6">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Stake Information
        </h5>
      </template>
      <div>
        <h6 class="text-center">
          HSI Address: <mark>{{ hsiAddress }}</mark>
        </h6>
        <br />
        <p class="text-center">
          You can view the peformance of this stake on
          <a
            :href="'https://stakehex.today/account/' + hsiAddress"
            target="_blank"
            >stakehex.today</a
          >
        </p>
        <p class="text-center">
          This page will contain more information in the future.
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal6 = false"
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { utils, Contract, BigNumber } from "ethers";
import { hexLaunch, hdrnLaunch, hsiABI } from "../../constants";
import VueCountdown from "@chenfengyuan/vue-countdown";
import BaseDropdown from "@/components/BaseDropdown";
import Modal from "@/components/Modal.vue";

export default {
  name: "Stakes",
  components: {
    VueCountdown: VueCountdown,
    BaseDropdown,
    Modal
  },
  beforeCreate() {
    this.utils = utils;
  },
  data: function() {
    return {
      stakes: [],
      hexLaunch: hexLaunch,
      hdrnLaunch: hdrnLaunch,
      hsiIndex: null,
      hsiAddress: null,
      hsiTokenId: null,
      hdrnBalance: BigNumber.from(0),
      estLoanAmount: 0,
      estLoanLength: 0,
      estPayment: BigNumber.from(0),
      estInterest: BigNumber.from(0),
      loanStart: 0,
      paymentsMade: 0,
      totalPayments: 0,
      transferAddress: null,
      loanPending: false,
      tokenizePending: false,
      detokenizePending: false,
      paymentPending: false,
      payoffPending: false,
      transferPending: false,
      hedronSubscribed: false,
      hsimSubscribed: false,
      now: new Date(),
      loaded: false,
      modals: {
        modal0: false,
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false,
        modal6: false
      }
    };
  },
  computed: mapState({
    connected: state => state.ethers.connected,
    network: state => state.ethers.network,
    wallet: state => state.ethers.wallet,
    address: state => state.ethers.address,
    hex: state => state.ethers.hex,
    hedron: state => state.ethers.hedron,
    hsim: state => state.ethers.hsim
  }),
  methods: {
    getContractData: async function() {
      let stakes = [];

      let stakeCount = await this.hex.stakeCount(this.address);
      let hsiCount = await this.hsim.hsiCount(this.address);
      let hsiTokenCount = await this.hsim.balanceOf(this.address);

      for (let x = 0; x < hsiTokenCount; x++) {
        let tokenId = await this.hsim.tokenOfOwnerByIndex(this.address, x);
        let hsiAddress = await this.hsim.hsiToken(tokenId);
        let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
        let share = await hsi.share();

        let now = new Date();
        let timeDelta = now.getTime() - this.hexLaunch.getTime();
        let servedDays = timeDelta / (1000 * 3600 * 24);
        servedDays = servedDays - share.stake.lockedDay;
        servedDays = Math.floor(servedDays);

        if (servedDays > share.stake.stakedDays) {
          servedDays = share.stake.stakedDays;
        }

        let mintableDays = servedDays - share.mintedDays;
        let mintableICS = share.stake.stakeShares.mul(mintableDays);
        mintableICS = mintableICS.add(
          mintableICS.mul(share.launchBonus).div(10)
        );

        let loanableDays = share.stake.stakedDays - share.mintedDays;

        if (share.isLoaned) {
          loanableDays = 0;
        }

        let loanableHDRN = share.stake.stakeShares.mul(loanableDays);

        let totalPayments = Math.ceil(share.loanedDays / 30);

        timeDelta = now.getTime() - this.hdrnLaunch.getTime();
        let hdrnDay = Math.floor(timeDelta / (1000 * 3600 * 24));

        let today = await this.hedron.dailyDataList(hdrnDay);
        let amrBonus = today.dayMintMultiplier;

        stakes.push({
          stakeType: "Instanced",
          stakeIndex: x,
          stakeId: share.stake.stakeId,
          stakeShares: share.stake.stakeShares,
          stakedDays: share.stake.stakedDays,
          servedDays: servedDays,
          mintedDays: share.mintedDays,
          mintableDays: mintableDays,
          loanedDays: share.loanedDays,
          loanableDays: loanableDays,
          bonusMultiplier: (share.launchBonus + amrBonus) / 10,
          mintableICS: mintableICS,
          loanableHDRN: loanableHDRN,
          isLoaned: share.isLoaned,
          loanStart: share.loanStart,
          paymentsMade: share.paymentsMade,
          totalPayments: totalPayments,
          tokenId: tokenId,
          isTokenized: true,
          hsiIndex: x,
          hsiAddress: hsiAddress,
          claimed: true,
          buttonIcon: "fa fa-share",
          buttonText: "Mint",
          minting: false,
          ending: false,
          accounting: false,
          detokenizing: false
        });
      }

      for (let x = 0; x < hsiCount; x++) {
        let hsiAddress = await this.hsim.hsiLists(this.address, x);
        let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
        let share = await hsi.share();

        let now = new Date();
        let timeDelta = now.getTime() - this.hexLaunch.getTime();
        let servedDays = timeDelta / (1000 * 3600 * 24);
        servedDays = servedDays - share.stake.lockedDay;
        servedDays = Math.floor(servedDays);

        if (servedDays > share.stake.stakedDays) {
          servedDays = share.stake.stakedDays;
        }

        let mintableDays = servedDays - share.mintedDays;
        let mintableICS = share.stake.stakeShares.mul(mintableDays);
        mintableICS = mintableICS.add(
          mintableICS.mul(share.launchBonus).div(10)
        );

        let loanableDays = share.stake.stakedDays - share.mintedDays;

        if (share.isLoaned) {
          loanableDays = 0;
        }

        let loanableHDRN = share.stake.stakeShares.mul(loanableDays);

        let totalPayments = Math.ceil(share.loanedDays / 30);

        timeDelta = now.getTime() - this.hdrnLaunch.getTime();
        let hdrnDay = Math.floor(timeDelta / (1000 * 3600 * 24));

        let today = await this.hedron.dailyDataList(hdrnDay);
        let amrBonus = today.dayMintMultiplier;

        stakes.push({
          stakeType: "Instanced",
          stakeIndex: x,
          stakeId: share.stake.stakeId,
          stakeShares: share.stake.stakeShares,
          stakedDays: share.stake.stakedDays,
          servedDays: servedDays,
          mintedDays: share.mintedDays,
          mintableDays: mintableDays,
          loanedDays: share.loanedDays,
          loanableDays: loanableDays,
          bonusMultiplier: (share.launchBonus + amrBonus) / 10,
          mintableICS: mintableICS,
          loanableHDRN: loanableHDRN,
          isLoaned: share.isLoaned,
          loanStart: share.loanStart,
          paymentsMade: share.paymentsMade,
          totalPayments: totalPayments,
          tokenId: null,
          isTokenized: false,
          hsiIndex: x,
          hsiAddress: hsiAddress,
          claimed: true,
          buttonIcon: "fa fa-share",
          minting: false,
          ending: false,
          accounting: false,
          detokenizing: false
        });
      }

      for (let x = 0; x < stakeCount; x++) {
        let stake = await this.hex.stakeLists(this.address, x);
        let share = await this.hedron.shareList(stake.stakeId);

        if (stake.stakeId == share.stake.stakeId) {
          let now = new Date();
          let timeDelta = now.getTime() - this.hexLaunch.getTime();
          let servedDays = timeDelta / (1000 * 3600 * 24);
          servedDays = servedDays - stake.lockedDay;
          servedDays = Math.floor(servedDays);

          if (servedDays > stake.stakedDays) {
            servedDays = stake.stakedDays;
          }

          let mintableDays = servedDays - share.mintedDays;
          let mintableICS = share.stake.stakeShares.mul(mintableDays);
          mintableICS = mintableICS.add(
            mintableICS.mul(share.launchBonus).div(10)
          );

          timeDelta = now.getTime() - this.hdrnLaunch.getTime();
          let hdrnDay = Math.floor(timeDelta / (1000 * 3600 * 24));

          let today = await this.hedron.dailyDataList(hdrnDay);
          let amrBonus = today.dayMintMultiplier;

          stakes.push({
            stakeType: "Native",
            stakeIndex: x,
            stakeId: stake.stakeId,
            stakeShares: share.stake.stakeShares,
            lockedDay: stake.lockedDay + 1,
            stakedDays: stake.stakedDays,
            mintedDays: share.mintedDays,
            bonusMultiplier: (share.launchBonus + amrBonus) / 10,
            servedDays: servedDays,
            mintableDays: mintableDays,
            mintableICS: mintableICS,
            claimed: true,
            buttonIcon: "fa fa-share",
            buttonText: "Mint",
            minting: false
          });
        } else {
          let now = new Date();
          let timeDelta = now.getTime() - this.hexLaunch.getTime();
          let servedDays = timeDelta / (1000 * 3600 * 24);
          servedDays = servedDays - stake.lockedDay;
          servedDays = Math.floor(servedDays);

          timeDelta = now.getTime() - this.hdrnLaunch.getTime();
          let bonusDay = Math.floor(timeDelta / (1000 * 3600 * 24));
          bonusDay = 100 - bonusDay;

          if (bonusDay < 0) {
            bonusDay = 0;
          }

          let bonusMultiplier = 0;

          if (bonusDay > 90) {
            bonusMultiplier = 100;
          } else if (bonusDay > 80) {
            bonusMultiplier = 90;
          } else if (bonusDay > 70) {
            bonusMultiplier = 80;
          } else if (bonusDay > 60) {
            bonusMultiplier = 70;
          } else if (bonusDay > 50) {
            bonusMultiplier = 60;
          } else if (bonusDay > 40) {
            bonusMultiplier = 50;
          } else if (bonusDay > 30) {
            bonusMultiplier = 40;
          } else if (bonusDay > 20) {
            bonusMultiplier = 30;
          } else if (bonusDay > 10) {
            bonusMultiplier = 20;
          } else if (bonusDay > 0) {
            bonusMultiplier = 10;
          }

          let claimed = false;
          if (bonusMultiplier <= 0) {
            claimed = true;
          }

          if (servedDays > stake.stakedDays) {
            servedDays = stake.stakedDays;
          }

          let mintableICS = stake.stakeShares.mul(servedDays);
          mintableICS = mintableICS.add(
            mintableICS.mul(bonusMultiplier).div(10)
          );

          timeDelta = now.getTime() - this.hdrnLaunch.getTime();
          let hdrnDay = Math.floor(timeDelta / (1000 * 3600 * 24));

          let today = await this.hedron.dailyDataList(hdrnDay);
          bonusMultiplier = bonusMultiplier + today.dayMintMultiplier;

          stakes.push({
            stakeType: "Native",
            stakeIndex: x,
            stakeId: stake.stakeId,
            stakeShares: stake.stakeShares,
            lockedDay: stake.lockedDay + 1,
            stakedDays: stake.stakedDays,
            mintedDays: 0,
            bonusMultiplier: bonusMultiplier / 10,
            servedDays: servedDays,
            mintableDays: servedDays,
            mintableICS: mintableICS,
            claimed: claimed,
            buttonIcon: "fa fa-share",
            buttonText: "Mint",
            minting: false
          });
        }
      }
      this.stakes = stakes;
      this.loaded = true;
    },
    subscribeHedron: function() {
      if (!this.hedronSubscribed) {
        this.hedron.on("Claim", async (data, minter, stakeId) => {
          let dataArray = utils.arrayify(data);
          dataArray = utils.zeroPad(dataArray, 32);
          //const dataBuffer = dataArray.buffer;

          //const payout = new DataView (dataBuffer.slice(0, 15));
          //const bonusMultiplier = new DataView(dataBuffer.slice(15, 16));
          //const mintedDays = new DataView(dataBuffer.slice(16, 18));
          //const stakeShares = new DataView (dataBuffer.slice(18, 27));
          //const timestamp = new DataView (dataBuffer.slice(27, 32));

          if (minter == this.address || minter == this.hsiAddress) {
            for (let i = 0; i < this.stakes.length; i++) {
              if (this.stakes[i].stakeId == stakeId && this.stakes[i].minting) {
                this.stakes[i].bonusMultiplier =
                  BigNumber.from(dataArray.slice(0, 18)).toNumber() / 10;
                this.stakes[i].claimed = true;
                this.stakes[i].buttonIcon = "fa fa-share";
                this.stakes[i].buttonText = "Mint";
                this.stakes[i].minting = false;
              }
            }
          }
        });

        this.hedron.on("Mint", (data, minter, stakeId) => {
          let dataArray = utils.arrayify(data);
          dataArray = utils.zeroPad(dataArray, 32);

          //const payout = BigNumber.from(dataArray.slice(0, 15));
          //const bonusMultiplier = BigNumber.from(dataArray.slice(15, 16));
          //const mintedDays = BigNumber.from(dataArray.slice(16, 18));
          //const stakeShares = BigNumber.from(dataArray.slice(18, 27));
          //const timestamp = BigNumber.from(dataArray.slice(27, 32));

          if (minter == this.address || minter == this.hsiAddress) {
            for (let i = 0; i < this.stakes.length; i++) {
              if (this.stakes[i].stakeId == stakeId && this.stakes[i].minting) {
                let newMintedDays =
                  this.stakes[i].mintedDays +
                  BigNumber.from(dataArray.slice(16, 18)).toNumber();
                let mintableDays = this.stakes[i].servedDays - newMintedDays;
                let mintableICS = this.stakes[i].stakeShares * mintableDays;
                mintableICS =
                  mintableICS +
                  (mintableICS *
                    BigNumber.from(dataArray.slice(15, 16)).toNumber()) /
                    10;
                mintableICS =
                  Math.floor((mintableICS / Math.pow(10, 9)) * 100) / 100;

                this.stakes[i].mintedDays = newMintedDays;
                this.stakes[i].loanableDays =
                  this.stakes[i].stakedDays - newMintedDays;
                this.stakes[i].bonusMultiplier =
                  BigNumber.from(dataArray.slice(15, 16)).toNumber() / 10;
                this.stakes[i].mintableDays = mintableDays;
                this.stakes[i].mintableICS = mintableICS;
                this.stakes[i].buttonIcon = "fa fa-share";
                this.stakes[i].buttonText = "Mint";
                this.stakes[i].minting = false;
              }
            }
          }
        });
        this.hedron.on("LoanStart", async (data, borrower, stakeId) => {
          let dataArray = utils.arrayify(data);
          dataArray = utils.zeroPad(dataArray, 32);
          //const dataBuffer = dataArray.buffer;

          //const borrowed = new DataView (dataBuffer.slice(0, 12));
          //const interestRate = new DataView(dataBuffer.slice(12, 16));
          //const loanedDays = new DataView(dataBuffer.slice(16, 18));
          //const stakeShares = new DataView (dataBuffer.slice(18, 27));
          //const timestamp = new DataView (dataBuffer.slice(27, 32));

          if (borrower == this.address) {
            for (let i = 0; i < this.stakes.length; i++) {
              if (this.stakes[i].stakeId == stakeId && this.loanPending) {
                let timestamp = new Date(
                  BigNumber.from(dataArray.slice(27, 32)).toNumber() * 1000
                );

                let timeDelta = timestamp.getTime() - this.hdrnLaunch.getTime();
                let loanStart = Math.floor(timeDelta / (1000 * 3600 * 24));

                let newLoanedDays =
                  this.stakes[i].loanedDays +
                  BigNumber.from(dataArray.slice(16, 18)).toNumber();

                let totalPayments = Math.ceil(newLoanedDays / 30);

                this.stakes[i].loanStart = loanStart;
                this.stakes[i].loanedDays = newLoanedDays;
                this.stakes[i].totalPayments = totalPayments;
                this.stakes[i].loanableDays = 0;
                this.stakes[i].loanableHDRN = 0;
                this.stakes[i].isLoaned = true;
                this.loanPending = false;
                this.modals.modal1 = false;
              }
            }
          }
        });

        this.hedron.on("LoanPayment", (data, borrower, stakeId) => {
          if (borrower == this.address) {
            for (let i = 0; i < this.stakes.length; i++) {
              if (this.stakes[i].stakeId == stakeId && this.paymentPending) {
                this.paymentPending = false;
                this.modals.modal3 = false;
                this.stakes[i].paymentsMade = this.stakes[i].paymentsMade + 1;
              }
            }
          }
        });

        this.hedron.on("LoanEnd", (data, borrower, stakeId) => {
          let dataArray = utils.arrayify(data);
          dataArray = utils.zeroPad(dataArray, 32);
          //const dataBuffer = dataArray.buffer;

          //const borrowed = new DataView (dataBuffer.slice(0, 12));
          //const interestRate = new DataView(dataBuffer.slice(12, 16));
          //const loanedDays = new DataView(dataBuffer.slice(16, 18));
          //const stakeShares = new DataView (dataBuffer.slice(18, 27));
          //const timestamp = new DataView (dataBuffer.slice(27, 32));

          if (borrower == this.address) {
            for (let i = 0; i < this.stakes.length; i++) {
              if (this.stakes[i].stakeId == stakeId && this.payoffPending) {
                this.payoffPending = false;
                this.modals.modal4 = false;

                let loanableDays =
                  this.stakes[i].stakedDays - this.stakes[i].mintedDays;

                let loanableHDRN = BigNumber.from(dataArray.slice(18, 27)).mul(
                  loanableDays
                );

                this.stakes[i].loanedDays = 0;
                this.stakes[i].loanableDays = loanableDays;
                this.stakes[i].loanableHDRN = loanableHDRN;
                this.stakes[i].isLoaned = false;
                this.stakes[i].loanStart = 0;
                this.stakes[i].paymentsMade = 0;
                this.stakes[i].totalPayments = 0;
              }
            }
          }
        });

        this.hedron.on(
          "LoanLiquidateExit",
          async (data, liquidator, stakeId, liquidationId) => {
            if (liquidator == this.address) {
              let liquidation = await this.hedron.liquidationList(
                liquidationId
              );

              let hsi = new Contract(
                liquidation.hsiAddress,
                hsiABI,
                this.wallet
              );
              let share = await hsi.share();

              let now = new Date();
              let timeDelta = now.getTime() - this.hexLaunch.getTime();
              let servedDays = timeDelta / (1000 * 3600 * 24);
              servedDays = servedDays - share.stake.lockedDay;
              servedDays = Math.floor(servedDays);

              if (servedDays > share.stake.stakedDays) {
                servedDays = share.stake.stakedDays;
              }

              let mintableDays = servedDays - share.mintedDays;
              let mintableICS = share.stake.stakeShares.mul(mintableDays);
              mintableICS = mintableICS.add(
                mintableICS.mul(share.launchBonus).div(10)
              );

              let loanableDays = share.stake.stakedDays - share.mintedDays;

              if (share.isLoaned) {
                loanableDays = 0;
              }

              let loanableHDRN = share.stake.stakeShares.mul(loanableDays);

              let totalPayments = Math.ceil(share.loanedDays / 30);

              let hsiIndex = 0;
              let hsiCount = await this.hsim.hsiCount(this.address);

              for (let y = 0; y < hsiCount; y++) {
                let hsiAddress = await this.hsim.hsiLists(this.address, y);
                if (hsiAddress == liquidation.hsiAddress) {
                  hsiIndex = y;
                }
              }

              this.stakes.unshift({
                stakeType: "Instanced",
                stakeIndex: 0,
                stakeId: share.stake.stakeId,
                stakeShares: share.stake.stakeShares,
                stakedDays: share.stake.stakedDays,
                servedDays: servedDays,
                mintedDays: share.mintedDays,
                mintableDays: mintableDays,
                loanedDays: share.loanedDays,
                loanableDays: loanableDays,
                bonusMultiplier: share.launchBonus / 10,
                mintableICS: mintableICS,
                loanableHDRN: loanableHDRN,
                isLoaned: share.isLoaned,
                loanStart: share.loanStart,
                paymentsMade: share.paymentsMade,
                totalPayments: totalPayments,
                tokenId: null,
                isTokenized: false,
                hsiIndex: hsiIndex,
                hsiAddress: liquidation.hsiAddress,
                claimed: true,
                buttonIcon: "fa fa-share",
                buttonText: "Mint",
                minting: false,
                ending: false,
                accounting: false,
                detokenizing: false
              });
            }
          }
        );

        this.hedronSubscribed = true;
      }
    },
    subscribeHsim: async function() {
      if (!this.hsimSubscribed) {
        this.hsim.on(
          "HSIStart",
          async (timestamp, instanceAddr, stakerAddr) => {
            if (stakerAddr == this.address) {
              let hsi = new Contract(instanceAddr, hsiABI, this.wallet);
              let share = await hsi.share();

              let now = new Date();
              let timeDelta = now.getTime() - this.hexLaunch.getTime();
              let servedDays = timeDelta / (1000 * 3600 * 24);
              servedDays = servedDays - share.stake.lockedDay;
              servedDays = Math.floor(servedDays);

              if (servedDays > share.stake.stakedDays) {
                servedDays = share.stake.stakedDays;
              }

              let mintableDays = servedDays - share.mintedDays;
              let mintableICS = share.stake.stakeShares.mul(mintableDays);
              mintableICS = mintableICS.add(
                mintableICS.mul(share.launchBonus).div(10)
              );

              let loanableDays = share.stake.stakedDays - share.mintedDays;

              if (share.isLoaned) {
                loanableDays = 0;
              }

              let loanableHDRN = share.stake.stakeShares.mul(loanableDays);

              let totalPayments = Math.ceil(share.loanedDays / 30);

              let hsiIndex = 0;
              let hsiCount = await this.hsim.hsiCount(this.address);

              for (let y = 0; y < hsiCount; y++) {
                let hsiAddress = await this.hsim.hsiLists(this.address, y);
                if (hsiAddress == instanceAddr) {
                  hsiIndex = y;
                }
              }

              this.stakes.unshift({
                stakeType: "Instanced",
                stakeIndex: 0,
                stakeId: share.stake.stakeId,
                stakeShares: share.stake.stakeShares,
                stakedDays: share.stake.stakedDays,
                servedDays: servedDays,
                mintedDays: share.mintedDays,
                mintableDays: mintableDays,
                loanedDays: share.loanedDays,
                loanableDays: loanableDays,
                bonusMultiplier: share.launchBonus / 10,
                mintableICS: mintableICS,
                loanableHDRN: loanableHDRN,
                isLoaned: share.isLoaned,
                loanStart: share.loanStart,
                paymentsMade: share.paymentsMade,
                totalPayments: totalPayments,
                tokenId: null,
                isTokenized: false,
                hsiIndex: hsiIndex,
                hsiAddress: instanceAddr,
                claimed: true,
                buttonIcon: "fa fa-share",
                buttonText: "Mint",
                minting: false,
                ending: false,
                accounting: false,
                detokenizing: false
              });
            }
          }
        );

        this.hsim.on("HSIEnd", async (timestamp, instanceAddr, stakerAddr) => {
          if (stakerAddr == this.address) {
            let stakeFound = false;
            let stakeToSplice;

            for (let i = 0; i < this.stakes.length; i++) {
              if (this.stakes[i].stakeType == "Instanced") {
                let hsiCount = await this.hsim.hsiCount(this.address);

                if (this.stakes[i].ending) {
                  if (this.stakes[i].hsiAddress == instanceAddr) {
                    stakeFound = true;
                    stakeToSplice = i;
                  }
                } else {
                  for (let x = 0; x < hsiCount; x++) {
                    let hsiAddress = await this.hsim.hsiLists(this.address, x);
                    let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
                    let share = await hsi.share();

                    if (share.stake.stakeId == this.stakes[i].stakeId) {
                      this.stakes[i].hsiIndex = x;
                    }
                  }
                }
              }
            }
            if (stakeFound) {
              this.stakes[stakeToSplice].ending = false;
              this.stakes.splice(stakeToSplice, 1);
            }
          }
        });

        this.hsim.on(
          "HSITokenize",
          async (timestamp, tokenId, instanceAddr, stakerAddr) => {
            if (stakerAddr == this.address && this.tokenizePending) {
              for (let i = 0; i < this.stakes.length; i++) {
                if (this.stakes[i].stakeType == "Instanced") {
                  let hsiCount = await this.hsim.hsiCount(this.address);

                  for (let x = 0; x < hsiCount; x++) {
                    let hsiAddress = await this.hsim.hsiLists(this.address, x);
                    let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
                    let share = await hsi.share();

                    if (share.stake.stakeId == this.stakes[i].stakeId) {
                      this.stakes[i].hsiIndex = x;
                    }
                  }
                }
                if (this.stakes[i].hsiAddress == instanceAddr) {
                  this.stakes[i].tokenId = tokenId;
                  this.stakes[i].isTokenized = true;
                  this.tokenizePending = false;
                  this.modals.modal2 = false;
                }
              }
            }
          }
        );

        this.hsim.on(
          "HSIDetokenize",
          async (timestamp, tokenId, instanceAddr, stakerAddr) => {
            if (stakerAddr == this.address) {
              for (let i = 0; i < this.stakes.length; i++) {
                if (
                  this.stakes[i].stakeType == "Instanced" &&
                  this.stakes[i].detokenizing
                ) {
                  let hsiCount = await this.hsim.hsiCount(this.address);

                  for (let x = 0; x < hsiCount; x++) {
                    let hsiAddress = await this.hsim.hsiLists(this.address, x);
                    let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
                    let share = await hsi.share();

                    if (share.stake.stakeId == this.stakes[i].stakeId) {
                      this.stakes[i].hsiIndex = x;
                    }
                  }
                }
                if (this.stakes[i].hsiAddress == instanceAddr) {
                  this.stakes[i].tokenId = null;
                  this.stakes[i].isTokenized = false;
                  this.stakes[i].detokenizing = false;
                }
              }
            }
          }
        );

        this.hsim.on("Transfer", (from, to, tokenId) => {
          if (from == this.address) {
            for (let i = 0; i < this.stakes.length; i++) {
              if (
                this.transferPending &&
                this.stakes[i].tokenId == tokenId.toNumber()
              ) {
                this.transferPending = false;
                this.modals.modal5 = false;
                this.stakes.splice(i, 1);
              }
            }
          }
        });

        this.hsimSubscribed = true;
      }
    },
    claimNative: async function(stakeIndex, stakeId) {
      for (let i = 0; i < this.stakes.length; i++) {
        if (this.stakes[i].stakeId == stakeId) {
          this.stakes[i].buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
          this.stakes[i].buttonText = "Ok";
          this.stakes[i].minting = true;
          await this.hedron.claimNative(stakeIndex, stakeId).catch(error => {
            this.stakes[i].buttonIcon = "fa fa-share";
            this.stakes[i].buttonText = "Mint";
            this.stakes[i].minting = false;
            console.log(error);
          });
        }
      }
    },
    mintNative: async function(stakeIndex, stakeId) {
      for (let i = 0; i < this.stakes.length; i++) {
        if (this.stakes[i].stakeId == stakeId) {
          this.stakes[i].buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
          this.stakes[i].buttonText = "Ok";
          this.stakes[i].minting = true;
          await this.hedron.mintNative(stakeIndex, stakeId).catch(error => {
            this.stakes[i].buttonIcon = "fa fa-share";
            this.stakes[i].buttonText = "Mint";
            this.stakes[i].minting = false;
            console.log(error);
          });
        }
      }
    },
    mintInstanced: async function(hsiIndex, hsiAddress) {
      for (let i = 0; i < this.stakes.length; i++) {
        if (this.stakes[i].hsiAddress == hsiAddress) {
          this.stakes[i].buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
          this.stakes[i].buttonText = "Ok";
          this.stakes[i].minting = true;
          if (this.stakes[i].servedDays < 0) {
            alert("You cannot mint against a pending HEX stake!");
            this.stakes[i].buttonIcon = "fa fa-share";
            this.stakes[i].buttonText = "Ok";
            this.stakes[i].minting = false;
          } else {
            await this.hedron
              .mintInstanced(hsiIndex, hsiAddress)
              .catch(error => {
                this.stakes[i].buttonIcon = "fa fa-share";
                this.stakes[i].buttonText = "Ok";
                this.stakes[i].minting = false;
                console.log(error);
              });
          }
        }
      }
    },
    calcPayment: async function() {
      let result = await this.hedron.calcLoanPayment(
        this.address,
        this.hsiIndex,
        this.hsiAddress
      );
      this.now = new Date();
      this.hdrnBalance = await this.hedron.balanceOf(this.address);
      this.estPayment = result[0];
      this.estInterest = result[1];
    },
    calcPayoff: async function() {
      let result = await this.hedron.calcLoanPayoff(
        this.address,
        this.hsiIndex,
        this.hsiAddress
      );
      this.now = new Date();
      this.hdrnBalance = await this.hedron.balanceOf(this.address);
      this.estPayment = result[0];
      this.estInterest = result[1];
    },
    loanInstanced: async function() {
      this.loanPending = true;
      await this.hedron
        .loanInstanced(this.hsiIndex, this.hsiAddress)
        .catch(error => {
          this.loanPending = false;
          console.log(error);
        });
    },
    stakeTokenize: async function() {
      this.tokenizePending = true;
      await this.hsim
        .hexStakeTokenize(this.hsiIndex, this.hsiAddress)
        .catch(error => {
          this.tokenizePending = false;
          console.log(error);
        });
    },
    stakeDetokenize: async function(tokenId) {
      for (let i = 0; i < this.stakes.length; i++) {
        if (this.stakes[i].tokenId == tokenId) {
          this.stakes[i].buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
          this.stakes[i].buttonText = "Ok";
          this.stakes[i].detokenizing = true;
          await this.hsim.hexStakeDetokenize(tokenId).catch(error => {
            this.stakes[i].buttonIcon = "fa fa-share";
            this.stakes[i].buttonText = "Ok";
            this.stakes[i].detokenizing = false;
            console.log(error);
          });
        }
      }
    },
    endStake: async function(hsiIndex, hsiAddress) {
      for (let i = 0; i < this.stakes.length; i++) {
        if (this.stakes[i].hsiAddress == hsiAddress) {
          if (this.stakes[i].servedDays < this.stakes[i].stakedDays) {
            if (
              confirm(
                "You are attempting to end a HEX stake early, there will most likely be penalties. Are you sure you wish to do this?"
              )
            ) {
              this.stakes[i].buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
              this.stakes[i].buttonText = "Ok";
              this.stakes[i].ending = true;
              await this.hsim.hexStakeEnd(hsiIndex, hsiAddress).catch(error => {
                this.stakes[i].buttonIcon = "fa fa-share";
                this.stakes[i].buttonText = "Ok";
                this.stakes[i].ending = false;
                console.log(error);
              });
            }
          } else {
            this.stakes[i].buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
            this.stakes[i].buttonText = "Ok";
            this.stakes[i].ending = true;
            await this.hsim.hexStakeEnd(hsiIndex, hsiAddress).catch(error => {
              this.stakes[i].buttonIcon = "fa fa-share";
              this.stakes[i].buttonText = "Ok";
              this.stakes[i].ending = false;
              console.log(error);
            });
          }
        }
      }
    },
    goodAccounting: async function(hsiAddress) {
      let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
      let share = await hsi.share();

      for (let i = 0; i < this.stakes.length; i++) {
        if (this.stakes[i].stakeId == share.stake.stakeId) {
          this.stakes[i].buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
          this.stakes[i].buttonText = "Ok";
          this.stakes[i].accounting = true;
          if (this.stakes[i].servedDays == this.stakes[i].stakedDays) {
            await hsi.goodAccounting().catch(error => {
              console.log(error.message);
            });
            this.stakes[i].buttonIcon = "fa fa-share";
            this.stakes[i].buttonText = "Ok";
            this.stakes[i].accounting = false;
          } else {
            alert(
              "You cannot use Good Accounting until the HEX stake has matured."
            );
            this.stakes[i].buttonIcon = "fa fa-share";
            this.stakes[i].buttonText = "Ok";
            this.stakes[i].accounting = false;
          }
        }
      }
    },
    loanPayment: async function() {
      this.paymentPending = true;
      await this.hedron
        .loanPayment(this.hsiIndex, this.hsiAddress)
        .catch(error => {
          this.paymentPending = false;
          console.log(error);
        });
    },
    loanPayoff: async function() {
      this.payoffPending = true;
      await this.hedron
        .loanPayoff(this.hsiIndex, this.hsiAddress)
        .catch(error => {
          this.payoffPending = false;
          console.log(error);
        });
    },
    hsiTransfer: async function() {
      this.transferPending = true;
      await this.hsim["safeTransferFrom(address,address,uint256)"](
        this.address,
        this.transferAddress,
        this.hsiTokenId
      ).catch(error => {
        this.transferPending = false;
        console.log(error);
      });
    }
  },
  watch: {
    address: function() {
      if (this.hdrnLaunch.getTime() <= this.now.getTime()) {
        this.loaded = false;
        this.stakes = [];
        this.getContractData();
        this.subscribeHedron();
        this.subscribeHsim();
      }
    }
  },
  async mounted() {
    if (this.connected) {
      if (this.hdrnLaunch.getTime() <= this.now.getTime()) {
        this.loaded = false;
        this.stakes = [];
        this.getContractData();
      }
    }
  }
};
</script>
<style>
.table-responsive .dropdown-menu {
  left: -150px;
}

ul.dropdown-menu.show::before {
  display: none;
}
</style>
