<template>
  <div class="pricing-5" id="pricing-6">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h3 class="display-3 mt-3 text-center">Estimate Your HDRN Payout!</h3>
          <br />
          <br />
          <p class="lead mt-0 text-center">
            This estimation may not be an exact representation of the amount of
            HDRN minted through the contract.
          </p>
        </div>
        <div class="col-md-7 ml-auto mr-auto">
          <card
            class="card-pricing text-center"
            type=""
            headerClasses="bg-transparent"
            footerClasses="bg-transparent"
          >
            <template slot="header">
              <h6 class="text-uppercase ls-1 py-3 mb-0">
                Hedron Minting Calculator
              </h6>
            </template>
            <template slot="body">
              <div class="row">
                <div class="col-md-7 ml-auto mr-auto">
                  <div class="form-group">
                    <input
                      style="width: 100%;"
                      v-model="bshares"
                      placeholder="HEX B-Shares"
                    />
                  </div>
                </div>
                <div class="col-md-7 ml-auto mr-auto">
                  <div class="form-group">
                    <input
                      style="width: 100%;"
                      v-model="daysStaked"
                      placeholder="Total Days Staked"
                    />
                  </div>
                </div>
                <div class="col-md-7 ml-auto mr-auto">
                  <div class="form-group">
                    <input
                      style="width: 100%;"
                      v-model="daysServed"
                      placeholder="Total Days Served"
                    />
                  </div>
                </div>
                <div class="col-md-7 ml-auto mr-auto">
                  <div class="form-group">
                    <span>Bonus Multiplier</span>
                    <select style="width: 100%;" v-model="bonusMultiplier">
                      <option
                        v-for="day in bonusDays"
                        v-bind:key="day.text"
                        v-bind:value="day.value"
                      >
                        {{ day.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-7 ml-auto mr-auto">
                <div class="form-group">
                  <base-button
                    icon="fa fa-calculator"
                    type="success"
                    class="mt-3 mb-sm-0"
                    v-on:click="calculate"
                    >Calculate</base-button
                  >
                </div>
              </div>
            </template>
            <template slot="footer">
              <b>Estimated HDRN: </b><b> {{ estimatedICS }} </b>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { utils, BigNumber } from "ethers";

export default {
  data: function() {
    return {
      bshares: "",
      daysStaked: "",
      daysServed: "",
      bonusMultiplier: 11,
      bonusDays: [
        { text: "10x", value: 11 },
        { text: "9x", value: 10 },
        { text: "8x", value: 9 },
        { text: "7x", value: 8 },
        { text: "6x", value: 7 },
        { text: "5x", value: 6 },
        { text: "4x", value: 5 },
        { text: "3x", value: 4 },
        { text: "2x", value: 3 },
        { text: "1x", value: 2 },
        { text: "None", value: 1 }
      ],
      estimatedICS: 0
    };
  },
  methods: {
    calculate: function() {
      let daysServed = parseInt(this.daysServed);
      let daysStaked = parseInt(this.daysStaked);

      if (daysServed > daysStaked) {
        daysServed = daysStaked;
      }

      let bshares = utils.parseUnits(this.bshares, 9);
      bshares = BigNumber.from(bshares);

      let bonusMultiplier = BigNumber.from(this.bonusMultiplier);

      let estimatedICS = bshares.mul(daysServed).mul(bonusMultiplier);
      this.estimatedICS = utils.formatUnits(estimatedICS, 9);
    }
  }
};
</script>
<style></style>
