<template>
  <header class="header-global">
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      :type="navbarType ? navbarType : ''"
      effect="dark"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="img/brand/white.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)">
            <img src="img/brand/black.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <router-link class="nav-link" to="/">Home</router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/dapp">Hedron DApp</router-link>
        </li>

        <li class="nav-item">
          <a class="nav-link" :href="uniswapAddr" target="_blank">Uniswap</a>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/media">Media</router-link>
        </li>

        <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <span class="nav-link-inner--text">Documentation</span>
          </a>
          <a
            class="dropdown-item"
            href="https://www.certik.com/projects/hedron"
            target="_blank"
            >Audit</a
          ><a
            class="dropdown-item"
            href="https://github.com/SeminaTempus/Hedron"
            target="_blank"
            >GitHub</a
          >
          <a class="dropdown-item" href="#/guide">Whitepaper</a>
          <a class="dropdown-item" href="#/docs">Documentation</a>
        </base-dropdown>
        <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <span class="nav-link-inner--text">Social Media</span>
          </a>
          <a
            class="dropdown-item"
            href="https://www.reddit.com/r/HedronCrypto/"
            target="_blank"
            >Reddit</a
          >
          <a
            class="dropdown-item"
            href="https://twitter.com/HedronCrypto"
            target="_blank"
            >Twitter</a
          >
          <a
            class="dropdown-item"
            href="https://discord.gg/XEcMXjVhrP"
            target="_blank"
            >Discord</a
          >
          <a
            class="dropdown-item"
            href="https://t.me/HedronCrypto"
            target="_blank"
            >Telegram</a
          >
        </base-dropdown>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import Headroom from "headroom.js";
//import { hdrnAddr } from "../constants";
import BaseDropdown from "@/components/BaseDropdown";

export default {
  data: function() {
    return {
      uniswapAddr: ""
    };
  },
  components: {
    BaseNav,
    BaseDropdown,
    CloseButton
  },
  props: {
    navbarType: String
  },
  mounted: function() {
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 300,
      tolerance: {
        up: 30,
        down: 30
      }
    });
    headroom.init();
    this.uniswapAddr =
      "https://uniswap.hedron.pro/#/swap?outputCurrency=" +
      "0x3819f64f282bf135d62168C1e513280dAF905e06";
  }
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}
</style>
