/* eslint-disable */
import {
  MSGS,
  EVENT_CHANNEL,
  connect,
  connectGnosis,
  event,
  ready,
  getProvider,
  getWallet,
  getWalletAddress,
  getNetName,
  hasEns,
  Contract
} from './ethersConnect'

import WalletConnectProvider from "@walletconnect/web3-provider";

import {hexAddr, hexABI,
        hdrnAddr, hdrnABI, 
        hsimAddr, hsimABI } from '../../constants'

export default {
  async connect(ctx) {
    try {
      const oldAddress = ctx.state.address
      const oldNetwork = ctx.state.network

      const provider = getProvider()
      if (!provider) throw new Error(MSGS.NOT_CONNECTED)

      const wallet = getWallet()
      if (!wallet) throw new Error(MSGS.NO_WALLET)
      const address = await getWalletAddress()
      const network = await getNetName()

      const hex = new Contract(hexAddr, hexABI, wallet)
      const hedron = new Contract(hdrnAddr, hdrnABI, wallet)
      const hsim = new Contract(hsimAddr, hsimABI, wallet)

      if (network !== oldNetwork || address !== oldAddress) {
        ctx.commit('connected', true)
        ctx.commit('error', null)
        ctx.commit('provider', provider)
        ctx.commit('wallet', wallet)
        ctx.commit('address', address)
        ctx.commit('user', address)
        ctx.commit('network', network)
        ctx.commit('hex', hex)
        ctx.commit('hedron', hedron)
        ctx.commit('hsim', hsim)

        const msg = oldAddress && oldAddress !== address
          ? `Your Ethereum address/user has changed.
          Address: ${address}
          Network: ${network}
          Gas Price: ${await provider.getGasPrice()}
          Current Block #: ${await provider.getBlockNumber()}
          Your ether balance: ${await provider.getBalance(address)}`
          : `You are connected to the Ethereum Network.
          Address: ${address}
          Network: ${network}
          Gas Price: ${await provider.getGasPrice()}
          Current Block #: ${await provider.getBlockNumber()}
          Your ether balance: ${await provider.getBalance(address)}
          If you change your address or network, this app will update automatically.`
        //alert(msg)

        // Other vuex stores can wait for this
        event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_READY)

        // now check for .eth address too
        if (await hasEns()) {
          console.log('Net supports ENS. Checking...')
          //ctx.commit('ens', await provider.lookupAddress(address))
          //if (ctx.state.ens) {
            //ctx.commit('user', ens)
          //}
        }

        //provider.on('block', (blockNumber) => {
          //console.log('Block mined:', blockNumber)
        //})
      }
    } catch (err) {
      ctx.dispatch('disconnect', err)
    }
  },
  async disconnect(ctx, err) {
    const oldAddress = ctx.state.address
    ctx.commit('connected', false)
    ctx.commit('error', err)
    ctx.commit('address', '')
    ctx.commit('user', '')
    ctx.commit('network', '')
    ctx.commit('ens', null)

    const msg = err ? `There was an error: ${err.message}` : (oldAddress
      ? 'You have been disconnected from your Ethereum connection. Please check MetaMask, etc.'
      : 'You are not connected to an Ethereum node and wallet. Please check MetaMask, etc.')
    //alert(msg)
  },
  async logout(ctx) {
    ctx.commit('address', '')
    ctx.commit('user', '')
    //alert('You have been logged out from your Ethereum connection')
  },
  async notConnected(ctx) {
    ctx.commit('address', '')
    ctx.commit('user', '')
    //alert('You are not connected to the Ethereum network. Please check MetaMask,etc.')
  },
  async init(ctx) {

    event.$on(EVENT_CHANNEL, async function (msg) {
      console.log('Ethers event received', msg)
      switch (msg) {
        case MSGS.NOT_READY:
          await ctx.dispatch('disconnect')
          break
        case MSGS.NO_WALLET:
          await ctx.dispatch('logout')
          break
        case MSGS.ACCOUNT_CHANGED:
          await ctx.dispatch('connect')
          break
        case MSGS.NOT_CONNECTED:
          await ctx.dispatch('notConnected')
          break
      }
    })

    if (ready()) {
      await ctx.dispatch('connect')
      event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_INITIALIZED)
    } else {
      await connect()
    }

    ctx.commit('initialized', true)
  },
  async userConnect(ctx) {
    await connect()
  },
  async userConnectGnosis(ctx) {
    await connectGnosis()
  }
}
