<template>
  <div class="wrapper">
    <div class="page-header page-header-blank">
      <div class="container" style="padding-top: 100px; padding-bottom: 0px;">
        <div class="row">
          <div class="col-lg-6 mx-auto text-center">
            <div class="row">
              <div class="col-lg-12 mx-auto text-center">
                <base-button
                  icon="fa fa-search"
                  type="primary"
                  class="btn-sm"
                  v-on:click="
                    modals.modal3 = true;
                    fetchDefaulted();
                    fetchLiquidations();
                  "
                  >Browse Liquidations</base-button
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-auto text-center">
            <div class="row">
              <div class="col-lg-12 mx-auto text-center">
                <img
                  v-if="network == 'Ethereum'"
                  src="img/ethereum-logo-1.jpg"
                />
                <img
                  v-else-if="network == 'PulseChain' || network == 'PulseChain Testnet'"
                  src="img/wordmark.png"
                />
                <h5 v-else>
                  No Wallet or Invalid Chain!
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-lg-6 mx-auto text-center">
            <div class="row">
              <div class="col-lg-6 mx-auto text-center">
                <base-dropdown>
                  <base-button
                    icon="fa fa-chain"
                    type="primary"
                    class="btn-sm dropdown-toggle"
                    slot="title"
                    >Connect Wallet</base-button
                  >
                  <a class="dropdown-item" v-on:click="connect"
                    ><span>Metamask</span></a
                  >
                  <a class="dropdown-item" v-on:click="connectGnosis"
                    ><span>WalletConnect</span></a
                  >
                </base-dropdown>
              </div>
              <div class="col-lg-6 mx-auto text-center">
                <base-button
                  icon="fa fa-plus"
                  type="primary"
                  class="btn-sm"
                  v-on:click="addHdrnToken"
                  >Add HDRN To Metamask</base-button
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-auto text-center">
            <div class="row">
              <div class="col-6 mx-auto text-center">
                <base-button
                  type="primary"
                  class="btn-sm"
                  v-on:click="modals.modal2 = true"
                  >Create HEX Stake Instance</base-button
                >
                <modal :show.sync="modals.modal2">
                  <template v-slot:header>
                    <h5 class="modal-title" id="exampleModalLabel">
                      Create an Instanced HEX Stake
                    </h5>
                  </template>
                  <div>
                    <base-input
                      v-if="hexApprovePending || hexStakePending"
                      v-model="hexStakeAmount"
                      placeholder="Amount of HEX to Stake"
                      alternative
                      disabled
                    ></base-input>
                    <base-input
                      v-else
                      v-model="hexStakeAmount"
                      placeholder="Amount of HEX to Stake"
                      alternative
                    ></base-input>
                    <base-input
                      v-if="hexApprovePending || hexStakePending"
                      v-model="hexStakeDays"
                      placeholder="Length of Stake in Days"
                      alternative
                      disabled
                    ></base-input>
                    <base-input
                      v-else
                      v-model="hexStakeDays"
                      placeholder="Length of Stake in Days"
                      alternative
                    ></base-input>
                    <p
                      v-if="
                        (hexAllowance.eq(0) ||
                          hexAllowance.lt(
                            BigNumber.from(
                              utils.parseUnits(hexStakeAmount || '0', 8)
                            )
                          )) &&
                          !hexStakePending
                      "
                      style="color: red;"
                    >
                      You will need to make a HEX approval before staking.
                    </p>
                    <base-button
                      v-if="
                        (hexAllowance.eq(0) ||
                          hexAllowance.lt(
                            BigNumber.from(
                              utils.parseUnits(hexStakeAmount || '0', 8)
                            )
                          )) &&
                          !hexStakePending &&
                          !hexApprovePending
                      "
                      type="primary"
                      class="btn-sm"
                      v-on:click="hexApprove(constants.MaxUint256)"
                      >APPROVE UNLIMITED</base-button
                    >
                    <base-button
                      v-else-if="
                        (hexAllowance.eq(0) ||
                          hexAllowance.lt(
                            BigNumber.from(
                              utils.parseUnits(hexStakeAmount || '0', 8)
                            )
                          )) &&
                          !hexStakePending &&
                          hexApprovePending
                      "
                      type="secondary"
                      class="btn-sm"
                      disabled
                      >APPROVE UNLIMITED</base-button
                    >
                  </div>
                  <template slot="footer">
                    <base-button
                      type="secondary"
                      v-on:click="modals.modal2 = false"
                      >Close</base-button
                    >
                    <base-button
                      v-if="hexApprovePending || hexStakePending"
                      type="secondary"
                      icon="fa fa-spinner fa-pulse fa-fw"
                      disabled
                      >Please Wait</base-button
                    >
                    <base-button
                      v-else-if="
                        hexAllowance.eq(0) ||
                          hexAllowance.lt(
                            BigNumber.from(
                              utils.parseUnits(hexStakeAmount || '0', 8)
                            )
                          )
                      "
                      type="primary"
                      v-on:click="hexApprove(hexStakeAmount)"
                      >Approve HEX</base-button
                    >
                    <base-button v-else type="primary" v-on:click="hexStake"
                      >Stake HEX</base-button
                    >
                  </template>
                </modal>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 30px;">
          <div class="col-lg-6 mx-auto text-center">
            <div class="row">
              <div v-if="hexDailyData" class="col-lg-12 mx-auto text-center">
                <apexchart
                  type="area"
                  :options="hexOptions"
                  :series="hexSeries"
                ></apexchart>
              </div>
              <div
                v-else-if="address"
                class="col-lg-12 mx-auto text-center"
                style="margin-top: 92px; margin-bottom: 92px;"
              >
                <span class="fa fa-spinner fa-pulse fa-3x fa-fw"></span>
                <h5 class="title">Loading HEX Daily Data...</h5>
                <p>This might take a little while...</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-auto text-center">
            <div class="row">
              <div v-if="hdrnDailyData" class="col-lg-12 mx-auto text-center">
                <apexchart
                  type="area"
                  :options="hdrnOptions"
                  :series="hdrnSeries"
                ></apexchart>
              </div>
              <div
                v-else
                class="col-lg-12 mx-auto text-center"
                style="margin-top: 92px; margin-bottom: 92px;"
              >
                <span class="fa fa-spinner fa-pulse fa-3x fa-fw"></span>
                <h5 class="title">Loading Hedron Daily Data...</h5>
                <p>This might take a little while...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <base-alert type="default" class="text-center">
            <div class="row">
              <div class="col-md-6 mx-auto">
                <strong>Advance to Mint Ratio</strong>:
                {{ borrowedSupply.mul(10000).div(totalSupply || 1) / 100 }}% of
                HDRN supply is currently borrowed.
              </div>
              <div class="col-md-2 mx-auto">
                <strong>Today's AMR Bonus</strong>: {{ amrBonus }}x
              </div>
              <div class="col-md-4 mx-auto">
                <strong>Tomorrow's Projected AMR Bonus</strong>:
                {{ projectedAmr }}x
              </div>
            </div>
          </base-alert>
        </div>
      </div>
    </div>
    <Stakes></Stakes>
    <modal class="liquidationBrowser" :show.sync="modals.modal3">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Liquidation Browser
        </h5>
      </template>
      <div>
        <tabs pills fill>
          <tab-pane active>
            <template slot="label">
              <i class="fa fa-calendar mr-2"></i> Advances in Default
            </template>
            <el-table
              v-if="defaultedLoans.length > 0 && defaultedLoansLoaded"
              class="table table-responsive table-flush align-items-center"
              header-row-class-name="thead-light"
              :data="defaultedLoans"
            >
              <el-table-column
                label="Stake B-Shares"
                label-class-name="text-center"
              >
                <template v-slot="{ row }" class="text-center">
                  <div class="text-center">
                    {{ Math.ceil(utils.formatUnits(row.stakeShares, 7)) / 100 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Served Days"
                label-class-name="text-center"
              >
                <template v-slot="{ row }">
                  <div class="d-flex align-items-center">
                    <div>
                      <span
                        class="completion"
                        style="display: table; margin: 0 auto;"
                        >{{ row.servedDays }} / {{ row.stakedDays }} ({{
                          Math.floor((row.servedDays / row.stakedDays) * 100)
                        }}%)</span
                      >
                      <base-progress
                        type="success"
                        style="padding-top: 0.5rem !important;"
                        :value="
                          Math.floor((row.servedDays / row.stakedDays) * 100)
                        "
                      ></base-progress>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Mintable HDRN"
                label-class-name="text-center"
              >
                <template v-slot="{ row }">
                  <div class="text-center">
                    {{
                      String(
                        Math.ceil(utils.formatUnits(row.mintableHDRN, 7)) / 100
                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Starting Bid"
                label-class-name="text-center"
              >
                <template v-slot="{ row }">
                  <div class="text-center">
                    {{
                      String(
                        Math.ceil(utils.formatUnits(row.startingBid, 7)) / 100
                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Actions" label-class-name="text-center">
                <template v-slot="{ row }">
                  <div class="text-center">
                    <el-dropdown
                      v-if="!row.accounting && !row.liquidating && row.timeUntil <= 0"
                      trigger="click"
                      :hide-on-click="true"
                      class="dropdown"
                    >
                      <span class="btn-sm btn-primary text-white">
                        ACTIONS
                      </span>
                      <el-dropdown-menu
                        class="dropdown-menu dropdown-menu-arrow show"
                        slot="dropdown"
                      >
                        <el-dropdown-item
                          class="dropdown-item"
                          @click.native="
                            hsiIndex = row.hsiIndex;
                            hsiAddress = row.hsiAddress;
                            borrower = row.borrower;
                            bidAmount = row.startingBid;
                            getHdrnBalance();
                            modals.modal5 = true;
                          "
                          ><span>Liquidate</span></el-dropdown-item
                        >
                        <el-dropdown-item
                          class="dropdown-item"
                          @click.native="goodAccounting(row.hsiAddress)"
                          ><span>Good Accounting</span></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <base-button
                      v-else-if="row.timeUntil > 0"
                      :icon="row.buttonIcon"
                      type="secondary"
                      class="btn-sm"
                      disabled
                      >In {{ row.timeUntil }} Days</base-button
                    >
                    <base-button
                      v-else
                      :icon="row.buttonIcon"
                      type="secondary"
                      class="btn-sm"
                      disabled
                      >{{ row.buttonText }}</base-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div
              v-else-if="!defaultedLoansLoaded"
              class="row"
              style="height: 400px"
            >
              <div class="col-md-6 mx-auto text-center">
                <br />
                <br />
                <br />
                <span class="fa fa-spinner fa-pulse fa-5x fa-fw"></span>
                <h2 class="title mb-5">Loading Advances!</h2>
                <h5>This may take a little while.</h5>
              </div>
            </div>
            <div v-else class="row" style="height: 400px">
              <div class="col-md-10 mx-auto text-center">
                <br />
                <br />
                <span class="fa fa-calendar fa-5x"></span>
                <h2 class="title mb-5">No Defaulted Advances!</h2>
                <h5>
                  Looks like Hedron users know how to manage their portfolios!
                  Cool!
                </h5>
                <br />
                <p>
                  Come back on the next Hedron day to check for any new
                  defaults.
                </p>
              </div>
            </div>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <i class="fa fa-gavel mr-2"></i> Active Liquidation Auctions
            </template>
            <el-table
              v-if="liquidations.length > 0 && liquidationsLoaded"
              class="table table-responsive table-flush align-items-center"
              header-row-class-name="thead-light"
              :data="liquidations"
            >
              <ex-table-column
                label="Stake B-Shares"
                label-class-name="text-center"
              >
                <template v-slot="{ row }" class="text-center">
                  <div class="text-center">
                    {{ Math.ceil(utils.formatUnits(row.stakeShares, 7)) / 100 }}
                  </div>
                </template>
              </ex-table-column>
              <ex-table-column
                label="Served Days"
                label-class-name="text-center"
              >
                <template v-slot="{ row }">
                  <div class="d-flex align-items-center">
                    <div>
                      <span
                        class="completion"
                        style="display: table; margin: 0 auto;"
                        >{{ row.servedDays }} / {{ row.stakedDays }} ({{
                          Math.floor((row.servedDays / row.stakedDays) * 100)
                        }}%)</span
                      >
                      <base-progress
                        type="success"
                        style="padding-top: 0.5rem !important;"
                        :value="
                          Math.floor((row.servedDays / row.stakedDays) * 100)
                        "
                      ></base-progress>
                    </div>
                  </div>
                </template>
              </ex-table-column>
              <ex-table-column
                label="Time Remaining"
                label-class-name="text-center"
              >
                <template v-slot="{ row }">
                  <div class="text-center">
                    <vue-countdown
                      v-if="row.countdown - now > 0"
                      tag="h5"
                      :time="row.countdown - now"
                      @end="now = new Date()"
                      v-slot="{ hours, minutes, seconds }"
                    >
                      <badge
                        v-if="hours <= 1"
                        type="warning"
                        rounded
                        class="badge-sm"
                        >{{ String(hours).padStart(2, "0") }}:{{
                          String(minutes).padStart(2, "0")
                        }}:{{ String(seconds).padStart(2, "0") }}</badge
                      >
                      <badge v-else type="success" rounded class="badge-sm"
                        >{{ String(hours).padStart(2, "0") }}:{{
                          String(minutes).padStart(2, "0")
                        }}:{{ String(seconds).padStart(2, "0") }}</badge
                      >
                    </vue-countdown>
                    <h5 v-else-if="row.liquidator == address">
                      <badge type="success" rounded class="badge-sm"
                        >WINNER</badge
                      >
                    </h5>
                    <h5 v-else>
                      <badge type="warning" rounded class="badge-sm"
                        >LOST</badge
                      >
                    </h5>
                  </div>
                </template>
              </ex-table-column>
              <ex-table-column
                label="Current Bid (HDRN)"
                label-class-name="text-center"
              >
                <template v-slot="{ row }">
                  <h5 v-if="row.liquidator == address">
                    <badge
                      type="success"
                      rounded
                      class="badge-sm"
                      style="width: 100%"
                    >
                      {{
                        String(
                          Math.ceil(utils.formatUnits(row.bidAmount, 7)) / 100
                        ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</badge
                    >
                  </h5>
                  <h5 v-else style="width: 100%">
                    <badge
                      type="warning"
                      rounded
                      class="badge-sm"
                      style="width: 100%"
                    >
                      {{
                        String(
                          Math.ceil(utils.formatUnits(row.bidAmount, 7)) / 100
                        ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</badge
                    >
                  </h5>
                </template>
              </ex-table-column>
              <ex-table-column label="Actions" label-class-name="text-center">
                <template v-slot="{ row }">
                  <div class="text-center">
                    <el-dropdown
                      v-if="!row.exiting && row.countdown - now > 0"
                      trigger="click"
                      :hide-on-click="true"
                      class="dropdown"
                    >
                      <span class="btn-sm btn-primary text-white">
                        ACTIONS
                      </span>
                      <el-dropdown-menu
                        class="dropdown-menu dropdown-menu-arrow show"
                        slot="dropdown"
                      >
                        <el-dropdown-item
                          class="dropdown-item"
                          @click.native="
                            liquidationId = row.liquidationId;
                            bidAmount = row.bidAmount;
                            getHdrnBalance();
                            modals.modal6 = true;
                          "
                          ><span>Bid</span></el-dropdown-item
                        >
                        <el-dropdown-item
                          class="dropdown-item"
                          @click.native="
                            hsiAddress = row.hsiAddress;
                            modals.modal4 = true;
                          "
                          ><span>Stake Info</span></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown
                      v-else-if="!row.exiting && row.countdown - now <= 0"
                      trigger="click"
                      :hide-on-click="true"
                      class="dropdown"
                    >
                      <span class="btn-sm btn-primary text-white">
                        ACTIONS
                      </span>
                      <el-dropdown-menu
                        class="dropdown-menu dropdown-menu-arrow show"
                        slot="dropdown"
                      >
                        <el-dropdown-item
                          class="dropdown-item"
                          @click.native="liquidationExit(row.liquidationId)"
                          ><span>Exit</span></el-dropdown-item
                        >
                        <el-dropdown-item
                          class="dropdown-item"
                          @click.native="
                            hsiAddress = row.hsiAddress;
                            modals.modal4 = true;
                          "
                          ><span>Stake Info</span></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <base-button
                      v-else
                      :icon="row.buttonIcon"
                      type="secondary"
                      class="btn-sm"
                      disabled
                      >{{ row.buttonText }}</base-button
                    >
                  </div>
                </template>
              </ex-table-column>
            </el-table>
            <div
              v-else-if="!liquidationsLoaded"
              class="row"
              style="height: 400px"
            >
              <div class="col-md-6 mx-auto text-center">
                <br />
                <br />
                <br />
                <span class="fa fa-spinner fa-pulse fa-5x fa-fw"></span>
                <h2 class="title mb-5">Loading Liquidations!</h2>
                <h5>This may take a little while.</h5>
              </div>
            </div>
            <div v-else class="row" style="height: 400px">
              <div class="col-md-10 mx-auto text-center">
                <br />
                <br />
                <span class="fa fa-gavel fa-5x"></span>
                <h2 class="title mb-5">No Active Liquidations!</h2>
                <h5>
                  Looks like Hedron users know how to manage their portfolios!
                  Cool!
                </h5>
                <br />
                <p>
                  If there are advances in default you can start a liquidation
                  auction yourself at any time.
                </p>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal3 = false"
          >Close</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal4">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Stake Information
        </h5>
      </template>
      <div>
        <h6 class="text-center">
          HSI Address: <mark>{{ hsiAddress }}</mark>
        </h6>
        <br />
        <p class="text-center">
          You can view the peformance of this stake on
          <a
            :href="'https://stakehex.today/account/' + hsiAddress"
            target="_blank"
            >stakehex.today</a
          >
        </p>
        <p class="text-center">
          This page will contain more information in the future.
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal4 = false"
          >Close</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal5">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Start Liquidation Auction
        </h5>
      </template>
      <div>
        <h6 class="text-center">
          Starting Bid:
          <mark>{{
            String(
              utils.formatUnits(bidAmount || BigNumber.from(0), 9)
            ).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
          }}</mark>
          HDRN
        </h6>
        <br />
        <p class="text-center">
          This will start a 24 hour auction for the HEX stake used as collateral
          in this defaulted advance. The starting bid will be removed from your
          wallet if you proceed!
        </p>
        <br />
        <p class="text-center" style="font-size: 14px;">
          *Should you be outbid within this 24 hour period, the amount of HDRN
          you had bid will be returned to your wallet.
        </p>
        <p class="text-center" style="font-size: 14px;">
          **If additional bids are placed within the last 5 minutes of the
          auction, additional time will be added to the auction.
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal5 = false"
          >Close</base-button
        >
        <base-button
          v-if="liquidationPending"
          type="secondary"
          icon="fa fa-spinner fa-pulse fa-fw"
          disabled
          >Please Wait</base-button
        >
        <base-button
          v-else-if="hdrnBalance.gt(bidAmount || 0)"
          type="primary"
          v-on:click="loanLiquidate(borrower, hsiIndex, hsiAddress)"
          >Start Liquidation</base-button
        >
        <base-button v-else type="primary" disabled
          >Insufficient Balance</base-button
        >
      </template>
    </modal>
    <modal :show.sync="modals.modal6">
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">
          Bid on Liquidation
        </h5>
      </template>
      <div>
        <h6 class="text-center">
          Current Bid:
          <mark>{{
            String(
              utils.formatUnits(bidAmount || BigNumber.from(0), 9)
            ).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
          }}</mark>
          HDRN
        </h6>
        <br />
        <base-input
          v-if="bidPending"
          v-model="newBidAmount"
          placeholder="Amount of HDRN to Bid"
          alternative
          disabled
        ></base-input>
        <base-input
          v-else
          v-model="newBidAmount"
          placeholder="Amount of HDRN to Bid"
          alternative
        ></base-input>
        <br />
        <p class="text-center" style="font-size: 14px;">
          *Should you be outbid the amount of HDRN you had bid will be returned
          to your wallet.
        </p>
        <p class="text-center" style="font-size: 14px;">
          **If additional bids are placed within the last 5 minutes of the
          auction, additional time will be added to the auction.
        </p>
      </div>
      <template slot="footer">
        <base-button type="secondary" v-on:click="modals.modal6 = false"
          >Close</base-button
        >
        <base-button
          v-if="bidPending"
          type="secondary"
          icon="fa fa-spinner fa-pulse fa-fw"
          disabled
          >Please Wait</base-button
        >
        <base-button
          v-else-if="hdrnBalance.gt(utils.parseUnits(newBidAmount || '0', 9))"
          type="primary"
          v-on:click="liquidationBid()"
          >Submit Bid</base-button
        >
        <base-button v-else type="primary" disabled
          >Insufficient Balance</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import Stakes from "./sections/Stakes.vue";
import BaseAlert from "@/components/BaseAlert";
import BaseDropdown from "@/components/BaseDropdown";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { BigNumber, constants, Contract, utils } from "ethers";
import { mapState } from "vuex";
import ExTableColumn from "ex-table-column";
import {
  hexLaunch,
  hdrnAddr,
  hdrnLaunch,
  hsimAddr,
  hsiABI
} from "../constants";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from "element-ui";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { gql } from 'graphql-tag';

export default {
  name: "DApp",
  components: {
    BaseAlert,
    BaseDropdown,
    Stakes: Stakes,
    Modal,
    Tabs,
    TabPane,
    VueCountdown,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [ExTableColumn.name]: ExTableColumn
  },
  beforeCreate() {
    this.BigNumber = BigNumber;
    this.constants = constants;
    this.utils = utils;
  },
  data: function() {
    return {
      now: new Date(),
      hexBalance: BigNumber.from(0),
      hexAllowance: BigNumber.from(0),
      hdrnBalance: BigNumber.from(0),
      hexDepositAmount: null,
      hexWithdrawAmount: null,
      hexStakeAmount: null,
      hexStakeDays: null,
      defaultedLoans: [],
      defaultedLoansLoaded: false,
      liquidations: [],
      liquidationsLoaded: false,
      hsiIndex: null,
      hsiAddress: null,
      stakeId: null,
      liquidationId: null,
      borrower: null,
      bidAmount: null,
      newBidAmount: null,
      hexApprovePending: false,
      hexDepositPending: false,
      hexWithdrawPending: false,
      liquidationPending: false,
      bidPending: false,
      hexStakePending: false,
      hexDailyData: false,
      hexLaunch: hexLaunch,
      hdrnLaunch: hdrnLaunch,
      hdrnDailyData: false,
      hexSubscribed: false,
      hsimSubscribed: false,
      hedronSubscribed: false,
      blocked: false,
      liquidationSwitch: false,
      totalSupply: null,
      borrowedSupply: BigNumber.from(0),
      amrBonus: 0.0,
      projectedAmr: 0.0,
      hexOptions: {
        chart: {
          id: "hexDailyData"
        },
        xaxis: {
          type: "numeric",
          decimalsInFloat: 0,
          categories: [],
          title: {
            text: "HEX Daily Data"
          }
        },
        dataLabels: {
          enabled: false
        }
      },
      hexSeries: [
        {
          name: "Stake Payout",
          data: []
        },
        {
          name: "Stake T-Shares",
          data: []
        }
      ],
      hdrnOptions: {
        chart: {
          id: "hdrnDailyData"
        },
        xaxis: {
          type: "numeric",
          decimalsInFloat: 0,
          categories: [],
          title: {
            text: "Hedron Daily Data"
          }
        },
        dataLabels: {
          enabled: false
        }
      },
      hdrnSeries: [
        {
          name: "Minted",
          data: []
        },
        {
          name: "Borrowed",
          data: []
        },
        {
          name: "Burnt",
          data: []
        }
      ],
      modals: {
        modal0: false,
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false,
        modal6: false
      }
    };
  },
  computed: mapState({
    initialized: state => state.ethers.initialized,
    provider: state => state.ethers.provider,
    connected: state => state.ethers.connected,
    network: state => state.ethers.network,
    wallet: state => state.ethers.wallet,
    address: state => state.ethers.address,
    hex: state => state.ethers.hex,
    hedron: state => state.ethers.hedron,
    hsim: state => state.ethers.hsim
  }),
  methods: {
    connect: function() {
      this.$store.dispatch("ethers/userConnect");
    },
    connectGnosis: function() {
      this.$store.dispatch("ethers/userConnectGnosis");
    },
    addHdrnToken: async function() {
      const hdrnSymbol = "HDRN";
      const hdrnDecimals = 9;
      const method = "wallet_watchAsset";
      const params = {
        type: "ERC20",
        options: {
          address: hdrnAddr,
          symbol: hdrnSymbol,
          decimals: hdrnDecimals,
          image: "https://hedron.pro/img/token/hdrn/256.png"
        }
      };
      this.provider.send(method, params);
    },
    getHexDailyData: async function() {
      let now = new Date();
      let timeDelta = now.getTime() - this.hexLaunch.getTime();
      let hexDay = Math.floor(timeDelta / (1000 * 3600 * 24));

      this.hexOptions.xaxis.range = 30;

      for (let x = hexDay - 90; x < hexDay; x++) {
        let dailyData = await this.hex.dailyData(x);
        this.hexOptions.xaxis.categories.push(Math.floor(x + 1));
        this.hexSeries[0].data.push(
          Math.floor(dailyData.dayPayoutTotal.div(100000000))
        );
        this.hexSeries[1].data.push(
          Math.floor(dailyData.dayStakeSharesTotal.div(1000000000000))
        );
      }
      this.hexDailyData = true;
    },
    getHdrnDailyData: async function() {
      let now = new Date();
      let timeDelta = now.getTime() - this.hdrnLaunch.getTime();
      let hdrnDay = Math.floor(timeDelta / (1000 * 3600 * 24));

      this.hdrnOptions.xaxis.range = 30;

        for (let x = hdrnDay - 90; x < hdrnDay; x++) {
          let dailyData = await this.hedron.dailyDataList(x);
          this.hdrnOptions.xaxis.categories.push(Math.floor(x + 1));
          this.hdrnSeries[0].data.push(
            Math.floor(dailyData.dayMintedTotal.div(1000000000))
          );
          this.hdrnSeries[1].data.push(
            Math.floor(dailyData.dayLoanedTotal.div(1000000000))
          );
          this.hdrnSeries[2].data.push(
            Math.floor(dailyData.dayBurntTotal.div(1000000000))
          );
        }
        this.borrowedSupply = await this.hedron.loanedSupply();
        this.totalSupply = await this.hedron.totalSupply();

        let today = await this.hedron.dailyDataList(hdrnDay);
        this.amrBonus = today.dayMintMultiplier / 10;

        let projectedAmr = this.borrowedSupply.mul(100).div(this.totalSupply);
        if (projectedAmr.gt(50)) {
          this.projectedAmr = projectedAmr.sub(50).mul(2) / 10;
        }
      this.hdrnDailyData = true;
    },
    getHexBalance: async function() {
      this.hexBalance = await this.hex.balanceOf(this.address);
    },
    getHdrnBalance: async function() {
      this.hdrnBalance = await this.hedron.balanceOf(this.address);
    },
    subscribeHex: function() {
      if (!this.hexSubscribed) {
        this.hex.on("Approval", (owner, spender, value) => {
          if (
            this.hexApprovePending &&
            owner == this.address &&
            spender == hsimAddr
          ) {
            this.hexAllowance = value;
            this.hexApprovePending = false;
          }
        });
      }
    },
    subscribeHsim: function() {
      if (!this.hsimSubscribed) {
        this.hsim.on("HSIStart", (timestamp, instanceAddr, stakerAddr) => {
          if (this.hexStakePending && stakerAddr == this.address) {
            this.hexStakeAmount = null;
            this.hexStakeDays = null;
            this.hexStakePending = false;
            this.modals.modal2 = false;
          }
        });

        this.hsimSubscribed = true;
      }
    },
    subscribeHedron: function() {
      if (!this.hedronSubscribed) {
        this.hedron.on(
          "LoanLiquidateStart",
          async (data, borrower, stakeId, liquidationId) => {
            this.defaultedLoans.forEach(async (loan, index, object) => {
              if (stakeId == loan.stakeId) {
                let hsiCount = await this.hsim.hsiCount(constants.AddressZero);

                for (let i = 0; i < hsiCount; i++) {
                  let hsiAddress = await this.hsim.hsiLists(
                    constants.AddressZero,
                    i
                  );
                  let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
                  let share = await hsi.share();

                  if (share.stake.stakeId === loan.stakeId) {
                    let liquidation = await this.hedron.liquidationList(
                      liquidationId
                    );

                    let now = new Date();
                    let timeDelta = now.getTime() - this.hexLaunch.getTime();
                    let servedDays = timeDelta / (1000 * 3600 * 24);
                    servedDays = servedDays - share.stake.lockedDay;
                    servedDays = Math.floor(servedDays);

                    if (servedDays > share.stake.stakedDays) {
                      servedDays = share.stake.stakedDays;
                    }

                    if (liquidation.isActive) {
                      this.liquidations.push({
                        hsiIndex: i,
                        hsiAddress: hsiAddress,
                        stakeId: loan.stakeId,
                        stakeShares: share.stake.stakeShares,
                        lockedDay: share.stake.lockedDay,
                        stakedDays: share.stake.stakedDays,
                        mintedDays: share.mintedDays,
                        launchBonus: share.launchBonus,
                        loanStart: share.loanStart,
                        loanedDays: share.loanedDays,
                        interestRate: share.interestRate,
                        paymentsMade: share.paymentsMade,
                        isLoaned: share.isLoaned,
                        borrower: borrower,
                        servedDays: servedDays,
                        liquidationId: liquidationId,
                        liquidationStart: liquidation.liquidationStart,
                        bidAmount: liquidation.bidAmount,
                        liquidator: liquidation.liquidator,
                        endOffset: liquidation.endOffset,
                        countdown: new Date(
                          liquidation.liquidationStart
                            .add(liquidation.endOffset)
                            .add(86400)
                            .mul(1000)
                            .toNumber()
                        ),
                        buttonIcon: "fa fa-share",
                        buttonText: "Exit",
                        accounting: false,
                        bidding: false,
                        exiting: false
                      });
                    }
                  }
                }
                object.splice(index, 1);
                this.liquidationPending = false;
                this.modals.modal5 = false;
                this.now = new Date();
              }
            });
            let hsiCount = await this.hsim.hsiCount(borrower);

            for (let x = 0; x < hsiCount; x++) {
              let hsiAddress = await this.hsim.hsiLists(borrower, x);
              let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
              let share = await hsi.share();

              this.defaultedLoans.forEach(loan => {
                if (share.stake.stakeId == loan.stakeId) {
                  loan.hsiIndex = x;
                }
              });
            }
          }
        );
        this.hedron.on(
          "LoanLiquidateBid",
          (data, bidder, stakeId, liquidationId) => {
            this.liquidations.forEach(async loan => {
              if (stakeId === loan.stakeId) {
                let dataArray = utils.arrayify(data);
                dataArray = utils.zeroPad(dataArray, 32);

                let liquidation = await this.hedron.liquidationList(
                  liquidationId
                );

                loan.liquidator = bidder;
                loan.bidAmount = BigNumber.from(dataArray.slice(0, 27));
                loan.countdown = new Date(
                  liquidation.liquidationStart
                    .add(liquidation.endOffset)
                    .add(86400)
                    .mul(1000)
                    .toNumber()
                );
                this.bidPending = false;
                this.modals.modal6 = false;
                this.now = new Date();
              }
            });
          }
        );
        this.hedron.on("LoanLiquidateExit", (data, bidder, stakeId) => {
          this.liquidations.forEach((loan, index, object) => {
            if (stakeId === loan.stakeId) {
              object.splice(index, 1);
              this.now = new Date();
            }
          });
        });
        this.hedronSubscribed = true;
      }
    },
    hexApprove: async function(amount) {
      if (this.blocked) {
        alert("Hedron has not launched yet!");
      } else if (isNaN(amount) || amount === null) {
        alert("The HEX deposit amount must be a number!");
      } else {
        this.hexApprovePending = true;
        if (!(amount instanceof BigNumber)) {
          amount = utils.parseUnits(amount, 8);
          amount = BigNumber.from(amount);
        }
        await this.hex.approve(hsimAddr, amount).catch(error => {
          this.hexApprovePending = false;
          console.log(error);
        });
      }
    },
    hexStake: async function() {
      if (this.blocked) {
        alert("Hedron has not launched yet!");
      } else if (isNaN(this.hexStakeAmount) || this.hexStakeAmount === null) {
        alert("The HEX stake amount must be a number!");
      }

      if (isNaN(this.hexStakeDays) || this.hexStakeDays === null) {
        alert("The HEX stake length must be a number!");
      } else if (this.hexStakeDays <= 0 || this.hexStakeDays > 5555) {
        alert("The HEX stake length must be between 1 and 5,555!");
      } else {
        this.hexStakePending = true;
        let amount = utils.parseUnits(this.hexStakeAmount, 8);
        this.hexAllowance = this.hexAllowance.sub(BigNumber.from(amount));
        this.hexStakeDays = parseInt(this.hexStakeDays);
        await this.hsim
          .hexStakeStart(
            BigNumber.from(amount),
            BigNumber.from(this.hexStakeDays)
          )
          .catch(error => {
            this.hexAllowance = this.hexAllowance.add(BigNumber.from(amount));
            this.hexStakeAmount = null;
            this.hexStakeDays = null;
            this.hexStakePending = false;
            console.log(error);
          });
      }
    },
    loanLiquidate: async function() {
      if (this.blocked) {
        alert("Hedron has not launched yet!");
      } else {
        this.liquidationPending = true;
        await this.hedron
          .loanLiquidate(this.borrower, this.hsiIndex, this.hsiAddress)
          .catch(error => {
            this.liquidationPending = false;
            console.log(error);
          });
      }
    },
    liquidationBid: async function() {
      if (this.blocked) {
        alert("Hedron has not launched yet!");
      } else if (isNaN(this.newBidAmount)) {
        alert("The bid amount must be a number!");
      } else if (this.newBidAmount <= 0) {
        alert("Liquidation bid must be greater than current bid!");
      } else {
        this.bidPending = true;
        let amount = utils.parseUnits(this.newBidAmount, 9);
        await this.hedron
          .loanLiquidateBid(this.liquidationId, BigNumber.from(amount))
          .catch(error => {
            this.bidPending = false;
            if (
              error.data.message ==
              "execution reverted: HDRN: Liquidation bid must be greater than current bid"
            ) {
              alert("Liquidation bid must be greater than current bid!");
            } else {
              console.log(error);
            }
          });
      }
    },
    liquidationExit: async function(liquidationId) {
      this.liquidations.forEach(async loan => {
        if (loan.liquidationId == liquidationId) {
          loan.exiting = true;
          loan.buttonIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
          loan.buttonText = "Ok";

          let liquidation = await this.hedron.liquidationList(liquidationId);
          let hsiCount = await this.hsim.hsiCount(constants.AddressZero);
          let hsiIndex;
          for (let i = 0; i < hsiCount; i++) {
            let hsiAddress = await this.hsim.hsiLists(constants.AddressZero, i);

            if (hsiAddress === liquidation.hsiAddress) {
              hsiIndex = i;
            }
          }
          await this.hedron
            .loanLiquidateExit(hsiIndex, liquidationId)
            .catch(error => {
              loan.exiting = false;
              loan.buttonIcon = "fa fa-share";
              loan.buttonText = "Ok";

              console.log(error);
            });
        }
      });
    },
    goodAccounting: async function(hsiAddress) {
      let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
      let share = await hsi.share();

      for (let i = 0; i < this.defaultedLoans.length; i++) {
        if (this.defaultedLoans[i].stakeId == share.stake.stakeId) {
          this.defaultedLoans[i].buttonIcon =
            "fa fa-spinner fa-pulse fa-2x fa-fw";
          this.defaultedLoans[i].buttonText = "Ok";
          this.defaultedLoans[i].accounting = true;
          if (
            this.defaultedLoans[i].servedDays ==
            this.defaultedLoans[i].stakedDays
          ) {
            await hsi.goodAccounting().catch(error => {
              if (
                error.data.message ==
                "execution reverted: HEX: Stake already unlocked"
              ) {
                alert("HEX stake already good accounted!");
              } else {
                console.log(error.message);
              }
            });
            this.defaultedLoans[i].buttonIcon = "fa fa-share";
            this.defaultedLoans[i].buttonText = "Ok";
            this.defaultedLoans[i].accounting = false;
          } else {
            alert(
              "You cannot use Good Accounting until the HEX stake has matured."
            );
            this.defaultedLoans[i].buttonIcon = "fa fa-share";
            this.defaultedLoans[i].buttonText = "Ok";
            this.defaultedLoans[i].accounting = false;
          }
        }
      }
    },
    fetchDefaulted: async function() {
      let today = await this.hedron.currentDay();
      let until = today.add(60);
      const query = `
        query($today: Int) {
          loans(where: {liquidationDay_lte: $today, isActive: true}, orderBy: liquidationDay, orderDirection: asc) {
            borrower
            stakeId
            paymentsMade
            loanStartDay
            liquidationDay
          }
        }
      `

      if (this.defaultedLoans.length <= 0) {
        let response = await this.fetchSubgraph(query, until.toNumber());
        let liquidatableLoans = [];

        response.data.loans.forEach( loan => {
          liquidatableLoans.push({
            borrower: loan.borrower,
            stakeId: parseInt(loan.stakeId),
            paymentsMade: parseInt(loan.paymentsMade),
            startDay: parseInt(loan.loanStartDay),
            timeUntil: parseInt(loan.liquidationDay) - today.toNumber()
          })
        })

        for (let i = 0; i < liquidatableLoans.length; i++) {
          let loan = liquidatableLoans[i];
          let hsiCount = await this.hsim.hsiCount(loan.borrower);

          let hsiStats = {
            found: false,
            borrower: loan.borrower,
            expectedId: loan.stakeId,
            searchedIds: []
          }

          for (let i = 0; i < hsiCount; i++) {
            let hsiAddress = await this.hsim.hsiLists(loan.borrower, i);
            let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
            let share = await hsi.share();

            hsiStats.searchedIds.push(share.stake.stakeId);

            if (share.stake.stakeId === loan.stakeId) {
              hsiStats.found = true;
              let now = new Date();
              let timeDelta = now.getTime() - this.hexLaunch.getTime();
              let servedDays = timeDelta / (1000 * 3600 * 24);
              servedDays = servedDays - share.stake.lockedDay;
              servedDays = Math.floor(servedDays);

              if (servedDays > share.stake.stakedDays) {
                servedDays = share.stake.stakedDays;
              }

              let mintableDays = servedDays - share.mintedDays;
              let mintableHDRN = share.stake.stakeShares.mul(mintableDays);
              mintableHDRN = mintableHDRN.add(
                mintableHDRN.mul(share.launchBonus).div(10)
              );

              // TODO, change this to pull from calcLoanPayoff?
              timeDelta = now.getTime() - this.hdrnLaunch.getTime();
              let hedronDay = Math.floor(timeDelta / (1000 * 3600 * 24));

              let loanTermPaid = share.paymentsMade * 30;
              let loanTermRemaining = share.loanedDays - loanTermPaid;
              let outstandingDays = hedronDay - share.loanStart - loanTermPaid;

              if (outstandingDays > loanTermRemaining) {
                outstandingDays = loanTermRemaining;
              }

              let principal = share.stake.stakeShares.mul(loanTermRemaining);
              let interest = principal
                .mul(share.interestRate * outstandingDays)
                .div(1000000);

              this.defaultedLoans.push({
                hsiIndex: i,
                hsiAddress: hsiAddress,
                stakeId: loan.stakeId,
                stakeShares: share.stake.stakeShares,
                lockedDay: share.stake.lockedDay,
                stakedDays: share.stake.stakedDays,
                mintedDays: share.mintedDays,
                launchBonus: share.launchBonus,
                loanStart: share.loanStart,
                loanedDays: share.loanedDays,
                interestRate: share.interestRate,
                paymentsMade: share.paymentsMade,
                isLoaned: share.isLoaned,
                borrower: loan.borrower,
                servedDays: servedDays,
                mintableHDRN: mintableHDRN,
                startingBid: principal.add(interest),
                buttonIcon: null,
                buttonText: null,
                accounting: false,
                liquidating: false,
                timeUntil: loan.timeUntil
              });
            }
          }
          if (hsiStats.found === false) {
            console.log(hsiStats)
          }
        }
        this.defaultedLoansLoaded = true;
      }
    },
    fetchLiquidations: async function() {
      const query = `
        query($today: Int) {
          liquidations(where: {isActive: true}, orderBy: liquidationStarted, orderDirection: asc) {
            stakeId
            liquidationId
            borrower
          }
        }
      `

      if (this.liquidations.length <= 0) {
        let response = await this.fetchSubgraph(query, 0);
        let activeLiquidations = [];

        response.data.liquidations.forEach( liquidation => {
          activeLiquidations.push({
            borrower: liquidation.borrower,
            stakeId: parseInt(liquidation.stakeId),
            liquidationId: parseInt(liquidation.liquidationId)
          })
        })

        for (let i = 0; i < activeLiquidations.length; i++) {
          let active = activeLiquidations[i];
          let hsiCount = await this.hsim.hsiCount(constants.AddressZero);

          for (let i = 0; i < hsiCount; i++) {
            let hsiAddress = await this.hsim.hsiLists(constants.AddressZero, i);
            let hsi = new Contract(hsiAddress, hsiABI, this.wallet);
            let share = await hsi.share();

            if (share.stake.stakeId === active.stakeId) {
              let liquidation = await this.hedron.liquidationList(
                active.liquidationId
              );

              let now = new Date();
              let timeDelta = now.getTime() - this.hexLaunch.getTime();
              let servedDays = timeDelta / (1000 * 3600 * 24);
              servedDays = servedDays - share.stake.lockedDay;
              servedDays = Math.floor(servedDays);

              if (servedDays > share.stake.stakedDays) {
                servedDays = share.stake.stakedDays;
              }

              if (liquidation.isActive) {
                this.liquidations.push({
                  hsiIndex: i,
                  hsiAddress: hsiAddress,
                  stakeId: active.stakeId,
                  stakeShares: share.stake.stakeShares,
                  lockedDay: share.stake.lockedDay,
                  stakedDays: share.stake.stakedDays,
                  mintedDays: share.mintedDays,
                  launchBonus: share.launchBonus,
                  loanStart: share.loanStart,
                  loanedDays: share.loanedDays,
                  interestRate: share.interestRate,
                  paymentsMade: share.paymentsMade,
                  isLoaned: share.isLoaned,
                  borrower: active.borrower,
                  servedDays: servedDays,
                  liquidationId: active.liquidationId,
                  liquidationStart: liquidation.liquidationStart,
                  bidAmount: liquidation.bidAmount,
                  liquidator: liquidation.liquidator,
                  endOffset: liquidation.endOffset,
                  countdown: new Date(
                    liquidation.liquidationStart
                      .add(liquidation.endOffset)
                      .add(86400)
                      .mul(1000)
                      .toNumber()
                  ),
                  buttonIcon: "fa fa-share",
                  buttonText: "Exit",
                  accounting: false,
                  bidding: false,
                  exiting: false
                });
              }
            }
          }
        }
        this.liquidations = this.liquidations.sort(
          (a, b) => a.countdown - b.countdown
        );
        this.now = new Date();
        this.liquidations = this.liquidations.filter(
          loan =>
            loan.countdown - this.now > 0 || loan.liquidator == this.address
        );
        this.liquidationsLoaded = true;
      }
    },
    fetchSubgraph: async function(query, today) {
      const link = new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/seminatempus/hedron'
      })
      const cache = new InMemoryCache();
      
      const client = new ApolloClient({
        cache,
        link
      });

      let response;

      await client
      .query({
        query: gql(query),
        variables: {
          today: today
        }
      })
      .then((data) => response = data)
      .catch((err) => {
        console.log('Error fetching data: ', err)
      });

      return response;
    }
  },
  watch: {
    hex: async function() {
      if (this.connected && this.hex) {
        this.hexDailyData = false;
        this.hexOptions.xaxis.categories = [];
        this.hexSeries[0].data = [];
        this.hexSeries[1].data = [];
        this.getHexDailyData();
        this.subscribeHex();
        this.hexAllowance = await this.hex.allowance(this.address, hsimAddr);
        this.hexBalance = await this.hex.balanceOf(this.address);
      }
    },
    hedron: async function() {
      if (this.connected && this.hedron) {
        this.hdrnDailyData = false;
        this.hdrnOptions.xaxis.categories = [];
        this.hdrnSeries[0].data = [];
        this.hdrnSeries[1].data = [];
        this.hdrnSeries[2].data = [];
        this.getHdrnDailyData();
        this.subscribeHedron();
        this.hdrnBalance = await this.hedron.balanceOf(this.address);
      }
    },
    hsim: async function() {
      if (this.connected && this.hsim) {
        this.subscribeHsim();
      }
    },
    address: function() {
      let now = new Date();
      if (this.hdrnLaunch.getTime() > now.getTime()) {
        this.blocked = true;
      }
    }
  },
  async mounted() {
    if (!this.connected) {
      this.$store.dispatch("ethers/init");
    } else {
      let now = new Date();

      this.hexDailyData = false;
      this.hexOptions.xaxis.categories = [];
      this.hexSeries[0].data = [];
      this.hexSeries[1].data = [];
      this.getHexDailyData();
      this.hexAllowance = await this.hex.allowance(this.address, hsimAddr);

      this.hdrnDailyData = false;
      this.hdrnOptions.xaxis.categories = [];
      this.hdrnSeries[0].data = [];
      this.hdrnSeries[1].data = [];
      this.hdrnSeries[2].data = [];
      this.getHdrnDailyData();

      if (this.hdrnLaunch.getTime() > now.getTime()) {
        this.blocked = true;
      }
    }
  }
};
</script>
<style>
.liquidationBrowser .modal-dialog {
  max-width: 800px !important;
}

.liquidationBrowser .modal-body {
  padding-bottom: 0px !important;
}

.tab-content {
  padding-bottom: 0px !important;
}

.tab-content .table {
  margin-bottom: 0px !important;
}

.el-table__body-wrapper {
  height: 358.4px !important;
}
</style>
