<template>
  <div class="wrapper">
    <div class="page-header page-header-blank">
      <div
        class="container"
        style="padding-top: 87px; padding-bottom: 0px;"
      ></div>
    </div>
    <Media></Media>
  </div>
</template>

<script>
import Media from "./sections/Media";
export default {
  name: "MediaWrapper",
  components: {
    Media
  }
};
</script>
<style>
iframe {
  display: block;
  width: 100%;
  height: 90vh;
}
</style>
