<template>
  <div class="cd-section" id="projects">
    <Media1></Media1>
  </div>
</template>
<script>
import Media1 from "./Media/Media1";

export default {
  components: {
    Media1
  }
};
</script>
<style></style>
