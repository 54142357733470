<template>
  <div class="wrapper">
    <div class="page-header page-header-blank">
      <div class="container" style="padding-top: 87px; padding-bottom: 0px;">
        <iframe class="docs" src="docs/code/docs.html"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Docs"
};
</script>
<style>
.docs {
  display: block;
  width: 100%;
  height: 90vh !important;
}
</style>
