import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import LandingPage from "./views/LandingPage.vue";
import DApp from "./views/DApp.vue";
import Docs from "./views/Docs.vue";
import Guide from "./views/Guide.vue";
import MediaWrapper from "./views/MediaWrapper.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "landing-page",
      components: {
        header: AppHeader,
        default: LandingPage,
        footer: AppFooter
      }
    },
    {
      path: "/dapp",
      name: "dapp",
      components: {
        header: AppHeader,
        default: DApp
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/docs",
      name: "docs",
      components: {
        header: AppHeader,
        default: Docs
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/guide",
      name: "guide",
      components: {
        header: AppHeader,
        default: Guide
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/media",
      name: "media",
      components: {
        header: AppHeader,
        default: MediaWrapper
      },
      props: {
        header: { navbarType: "primary" }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
