<template>
  <div class="cd-section" id="accordion">
    <!--     *********     ACCORDION 1      *********      -->
    <div class="container accordion-1">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <h2 class="title mb-3 mt-5">
            You've Got Questions, We've Got Answers
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <collapse :multiple-active="false">
            <collapse-item name="Hedron to HEX">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                Is Hedron related to HEX in any official capacity?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                No, Hedron is a completely separate project from HEX and has no
                relation to HEX's founder or development team. Hedron was built
                by people who are passionate about HEX and want to bring more to
                the table for fellow HEX enthusiasts. By Hexicans, for Hexicans.
              </div>
            </collapse-item>
            <collapse-item name="Native HEX Stake">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                What is a Native HEX stake and what can I do with it?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                A native HEX stake is a stake which is created directly by the
                user interfacing with the HEX smart contract. This is more or
                less all known HEX stakes created before the launch of Hedron or
                any stake created via the
                <a href="https://go.hex.com">HEX user interface</a>.<br /><br />

                Native HEX stakes <b>can ONLY mint HDRN</b> they
                <b>cannot borrow nor be "tokenized"</b>.
              </div>
            </collapse-item>
            <collapse-item name="Instanced HEX Stake">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                What is an instanced HEX stake and what can I do with it?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                A HEX stake instance (HSI) is a smart contract which wraps
                around (encapsulates) a native HEX stake. These stakes can be
                created using the
                <a href="https://hedron.pro/#/dapp">Hedron user interface</a>.
                All HEX staking functions still take place on the HEX smart
                contract, however using this wrapper gives the user access to
                additional Hedron features.<br /><br />

                Instanced HEX stakes
                <b>can mint and borrow HDRN as well as be "tokenized"</b>.
              </div>
            </collapse-item>
            <collapse-item name="Hedron Minting">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                How does minting work?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                Minting simply looks at your HEX stake's allocated amount of
                B-Shares and allows you to mint HDRN tokens against those shares
                1:1 for every day served within the HEX stake.<br /><br />

                Specific details on the minting process can be found in the
                <a href="https://hedron.pro/#/guide">Hedron Contract Guide</a>.
              </div>
            </collapse-item>
            <collapse-item name="Hedron Claiming">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                What is the difference between minting and claiming?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                Claiming is only available during the launch bonus phase.
                Minting is always available within Hedron. Claiming will allow
                you to claim your launch bonus, but not actually mint you any
                HDRN. This gives you the option of being able to defer minting
                for a later date. Minting will also claim the launch bonus,
                however it will also mint you HDRN tokens immediately.
                <b>You can either claim or mint, not both</b><br /><br />

                Specific details on the minting process can be found in the
                <a href="https://hedron.pro/#/guide">Hedron Contract Guide</a>.
              </div>
            </collapse-item>
            <collapse-item name="Hedron Minting Risks">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                Does minting pose any risk to my HEX stakes?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                No, Hedron requires no special permissions over your wallet or
                HEX stakes in order to allow you to mint HDRN. No contract
                approvals of any sort are required in order to mint HDRN.
              </div>
            </collapse-item>
            <collapse-item name="Hedron Minting Dilution">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                Doesn't minting for every served day dilute HDRN compared to HEX
                B-Shares?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                The short answer is yes, however this is by design. HDRN is only
                intended to mint 1:1 against HEX B-Shares, not consistently be a
                1:1 representation of HEX B-Shares past and present.
                <br /><br />

                Because HEX stakes can be emergency unstaked, Hedron must have a
                time component to dilute those who choose to emergency unstake.
                This could be accomplished by only allowing a portion of one's
                B-Shares to be minted based on time served, but then you would
                only be able to mint fractions of HDRN per day in most cases,
                and decimals are no fun.<br /><br />

                By minting your total shares once per HEX day served, you
                achieve the same effect without the decimal problem.
                Additionally, as the HEX share price increases, the amount of
                mintable HDRN will decrease. The reduction to mintable HDRN as
                the share price increases is much more visually apparent this
                way, and will likely result in fractional HDRN minting when the
                increased share price results in B-Shares becoming difficult to
                obtain.<br /><br />

                This is a similar model to how many "minable" cryptocurrencies
                increase difficulty over time, except Hedron's "difficulty"
                increases in relation to the HEX share price.
              </div>
            </collapse-item>
            <collapse-item name="Hedron Minting Daily">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                Am I required to mint Hedron every day?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                No, you can mint HDRN at any point between the beginning and end
                of your HEX stake. That could be every day, every week, or in
                one big lump sum when your stake ends. The only exception is
                during the bonus phase. You will have to mint or claim your HDRN
                during the bonus phase to receive a bonus multiplier. Keep in
                mind, if you end your stake before minting your HDRN you will no
                longer be able to mint HDRN against that stake.
                <b>Mint your HDRN before ending your stake!</b>
              </div>
            </collapse-item>
            <collapse-item name="Hedron Minting Shares">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                Can I mint Hedron against HEX stakes that have less than one
                B-Share?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                Yes, you will just receive a fraction of one HDRN per served day
                (not including bonuses).
              </div>
            </collapse-item>
            <collapse-item name="Hedron Advances">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                How does borrowing work and why would I want to do that?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                If the user has an instanced HEX stake (HSI), they have ability
                to borrow all HDRN tokens they would otherwise be able to mint
                without having to serve the required days within the stake. This
                could be considered an advance on the mintable HDRN the stake is
                entitled to. Taking out a advance will lock the HEX stake and
                restrict the user from minting against it or tokenizing it. The
                user may still mint against or trade other stakes, just not any
                stake with an active advance.<br /><br />

                Payments are due to the Hedron contract in the form of HDRN
                every 30 Hedron days. The payment amounts are calculated in
                advance by the contract and will be displayed to you in the
                <a href="https://hedron.pro/#/dapp">Hedron user interface</a>.
                The premium rate is calculated by the contract to be half of the
                current global HEX APY on the day of advance initiation and is a
                fixed rate for the life of the advance. This premium is charged
                only to the payment principal, not the total balance. The
                contract burns all payments, including interest. No party
                directly benefits from these advances other than the user taking
                out the advance.<br /><br />

                If an advance goes 90 or more Hedron days without repayment,
                other users will have the ability to liquidate said advance by
                paying off the remaining balance of the advance in a liquidation
                auction. This allows the highest bidder to claim the
                collateralized HEX stake for themselves. It is vital to have a
                solid understanding of how these advances work and any
                applicable tax laws in your jurisdiction before utilizing them.
                <b>We are not financial nor tax advisors.</b><br /><br />

                Specific details on the borrowing process can be found in the
                <a href="https://hedron.pro/#/guide">Hedron Contract Guide</a>.
              </div>
            </collapse-item>
            <collapse-item name="Tradable HEX Stakes">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                How can I trade my HEX stakes with Hedron?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                If the user creates an instanced HEX stake (HSI), they have
                ability to "tokenize" said HSI into an ERC721 NFT token. This
                will remove the stake from the users control and issue them said
                NFT token which can be traded on any compatible NFT marketplace.
                If another user were to obtain said token they would then be
                able to "detokenize" it via the
                <a href="https://hedron.pro/#/dapp">Hedron user interface</a>.
                This will destroy the token and grant the new owner control of
                the underlying HEX stake.<br /><br />

                If you decide you no longer wish to trade said NFT you can
                always "detokenize" it yourself and regain control of the stake.
                <br />
                <br />

                Specific details on the "tokenization" / "detokenization"
                process can be found in the
                <a href="https://hedron.pro/#/guide">Hedron Contract Guide</a>.
              </div>
            </collapse-item>
            <collapse-item name="Hedron Launch Bonus">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                How exactly does the launch bonus work?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                When you mint or claim your HDRN during the first 100 days of
                Hedron's launch you will receive a bonus multiplier. This
                multiplier starts at 10x and decreases by 1x per 10 days of the
                phase. Whichever multiplier you receive, the Hedron smart
                contract will record it to your HEX stake, and that bonus will
                exist for the remaining life of said stake.<br /><br />

                For example, if you have a HEX stake with a total stake length
                of 100 days and you mint HDRN on that stake with a bonus
                multiplier of 10x. You will get that 10x bonus for all remaining
                99 days of the stake.<br /><br />

                The bonus HDRN you recieve is added on top of your normal
                payout. So if you mint 100 HDRN with a 3x bonus multiplier, you
                will receive <b>100 HDRN + 300 Bonus HDRN = 400 Total HDRN</b>
              </div>
            </collapse-item>
            <collapse-item name="Hedron LMR Bonus">
              <h5
                slot="title"
                class="mb-0 btn btn-link w-100 text-primary text-left"
              >
                How exactly does the Advance to Mint Ratio (AMR) bonus work?
                <i class="ni ni-bold-down float-right pt-1"></i>
              </h5>
              <div>
                In an effort to ensure there is enough freely minted HDRN tokens
                to satisfy advance premiums, the Hedron smart contract will
                offer a daily bonus should the ratio of borrowed HDRN to the
                total HDRN supply ever exceed 50%. Unlike the launch bonus, the
                advance to mint ratio (AMR) bonus is not permanently recorded to
                your stake. It is calculated daily and ranges from 0.2x to 10.0x
                based on the ratio of minted to borrowed HDRN.<br /><br />

                This bonus is also added on top of your normal HDRN payout
                including the launch bonus, if applicable. So if you mint 100
                HDRN with a 3x launch and 1.0x AMR bonus multiplier, you will
                receive
                <b
                  >100 Base HDRN + 300 Launch Bonus HDRN + 400 AMR Bonus HDRN =
                  800 Total HDRN</b
                >
              </div>
            </collapse-item>
          </collapse>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";

export default {
  components: {
    Collapse,
    CollapseItem
  }
};
</script>
<style></style>
