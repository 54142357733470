<template>
  <footer class="footer">
    <div class="container">
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-4">
          <div class="copyright">
            &copy; {{ year }} Semina Tempus, LLC -
            <a href="mailto:hedroncrypto@protonmail.com">Contact Us</a>
          </div>
        </div>
        <div class="col-md-2">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <a href="https://nomics.com/assets/hdrn-hedron" target="_blank"
                ><img src="img/nomics.svg" style="height: 18px;"
              /></a>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <router-link class="nav-link" to="/dapp">Hedron DApp</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="uniswapAddr" target="_blank"
                >Uniswap</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://twitter.com/HedronCrypto"
                target="_blank"
                >Twitter</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://t.me/HedronCrypto"
                target="_blank"
                >Telegram</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
// import { hdrnAddr } from "../constants";

export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
      uniswapAddr: ""
    };
  },
  mounted: function() {
    this.uniswapAddr =
      "https://uniswap.hedron.pro/#/swap?outputCurrency=" +
      "0x3819f64f282bf135d62168C1e513280dAF905e06";
  }
};
</script>
<style></style>
